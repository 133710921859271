import {
  IRadioInputProps,
  Link,
  RadioInputField,
  Typo,
} from 'components/ui-kit-v2';
import { useFormikContext } from 'formik';
import { MoneyFormatter } from 'lib';
import { Office } from 'lib/models/office-response.model';
import { getAuthLink } from 'modules/auth';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { DeliveryTypeCode, ShippingType } from 'modules/cart/api';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { useState, type JSX } from 'react';
import { OfficeModal } from './office-modal/office-modal';
import { StuffAccessories } from './stuff-accessory';
import { TOrderFormValues } from '../../../order-form.const';

type TStuffRadioInputProps = { delivery: ShippingType } & IRadioInputProps;

export const StuffRadioInput = ({
  delivery,
  ...props
}: TStuffRadioInputProps): JSX.Element | undefined => {
  const { setFieldValue } = useFormikContext<TOrderFormValues>();
  const { profile } = useUserInfoContext();
  const [isShowModal, setShowModal] = useState(false);

  const handleSelectOffice = (office: Office): void => {
    setFieldValue('office', office);
    setShowModal(false);
  };

  if ([DeliveryTypeCode.FOR_STAFF].includes(delivery.code)) {
    return (
      <>
        <OfficeModal
          isOpen={isShowModal}
          onClose={() => setShowModal(false)}
          onSelect={handleSelectOffice}
        />
        <RadioInputField
          key={delivery.id}
          size="s"
          name="shippingTypeCode"
          disabled={!profile?.isEmployee}
          label={delivery.name}
          value={delivery.code}
          description={
            profile?.isEmployee ? (
              'Бесплатно отправим в любой'
            ) : (
              <>
                Бесплатно и только для сотрудников.{' '}
                <Link href={getAuthLink(EAuthScreen.LOGIN)}>Войти</Link>
              </>
            )
          }
          rightAccessory={
            <Typo>{MoneyFormatter.format(+delivery.priceValue)}</Typo>
          }
          bottomAccessory={
            profile?.isEmployee ? (
              <StuffAccessories onClick={() => setShowModal(true)} />
            ) : undefined
          }
          {...props}
        />
      </>
    );
  }
};
