import cn from 'classnames';
import { Typo } from 'components/ui-kit-v2';
import { type JSX } from 'react';
import styles from './radio-button.module.scss';

export interface IRadioButtonProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'formAction' | 'children' | 'size'
  > {
  /**
   * Маска
   */
  label?: string;
  isBlock?: boolean;
}

export const RadioButton = ({
  label,
  value,
  className,
  disabled,
  name,
  isBlock,
  ...inputProps
}: IRadioButtonProps): JSX.Element => {
  return (
    <label
      className={cn(styles.container, className, { [styles.isBlock]: isBlock })}
    >
      <input
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        className={styles.radio}
        {...inputProps}
      />
      <div role="button" className={styles.button}>
        <Typo size="xs">{label}</Typo>
      </div>
    </label>
  );
};
