import { useToggle } from '@brand/utils';
import { Clickable } from 'components/ui-kit-v2/clickable';
import { Icon } from 'components/ui-kit-v2/icon-v2';
import { type JSX } from 'react';
import { Input } from '../input/input.component';
import { TLabelProps } from '../label/label.types';
export interface IInputPasswordProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
    Omit<TLabelProps, 'children' | 'rightAccessory' | 'leftAccessory'> {}

export const InputPassword = (props: IInputPasswordProps): JSX.Element => {
  const [isShow, toggle] = useToggle(false);

  return (
    <Input
      {...props}
      type={isShow ? 'text' : 'password'}
      rightAccessory={
        <Clickable onClick={toggle}>
          <Icon icon={isShow ? 'IcEyeOpen' : 'IcEyeClose'} />
        </Clickable>
      }
    />
  );
};
