import { useState } from 'react';

export function useKeyboardNav<T>(
  list: Array<T>,
  onSelect: (item: T) => void,
): {
  selectedIndex: number;
  onKeyDown: (event: React.KeyboardEvent) => void;
  resetIndex: VoidFunction;
} {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const onKeyDown = (event: React.KeyboardEvent): void => {
    const listLength = list.length - 1;
    switch (event.code) {
      case 'ArrowDown': {
        const index = selectedIndex + 1 > listLength ? 0 : selectedIndex + 1;
        return setSelectedIndex(index);
      }
      case 'ArrowUp': {
        const index = selectedIndex - 1 < 0 ? listLength : selectedIndex - 1;
        return setSelectedIndex(index);
      }
      case 'Enter': {
        event.preventDefault();
        const targetIndex = selectedIndex <= -1 ? 0 : selectedIndex;
        return list[targetIndex] ? onSelect(list[targetIndex]) : undefined;
      }
      case 'Tab': {
        const targetIndex = selectedIndex <= -1 ? 0 : selectedIndex;
        return list[targetIndex] ? onSelect(list[targetIndex]) : undefined;
      }
      default:
        return;
    }
  };

  const resetIndex = (): void => setSelectedIndex(-1);

  return { selectedIndex, onKeyDown, resetIndex };
}
