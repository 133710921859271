import { Box, Spacer } from 'components/ui-kit-v2';
import { useFormikContext } from 'formik';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { useEffect, type JSX } from 'react';
import { Address } from './components/address.component';
import { CreateOrder } from './components/create-order.component';
import { Delivery } from './components/delivery/delivery.component';
import { Personal } from './components/personal.component';
import { TOrderFormValues, updateValuesByProfile } from './order-form.const';

export const OrderForm = (): JSX.Element => {
  const { setValues } = useFormikContext<TOrderFormValues>();
  const { profile } = useUserInfoContext();

  useEffect(() => {
    if (profile) {
      setValues(updateValuesByProfile(profile));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValues, profile?.id]);

  return (
    <Box
      variant="flat"
      className="px-4 py-5"
      backgroundColor="Primitive-Neutral-1"
    >
      <Address />
      <Spacer size="8x" />
      <Delivery />
      <Spacer size="8x" />
      <Personal />
      <Spacer size="8x" />
      <div className="d-none d-m-block">
        <CreateOrder />
      </div>
    </Box>
  );
};
