import { type JSX } from 'react';
export const UnionIcon = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09799 0.435566C8.71375 0.313749 9.3498 0.25 10 0.25C10.6502 0.25 11.2862 0.313749 11.902 0.435565L12.6378 0.581117L12.3466 2.0526L11.6109 1.90705C11.0905 1.8041 10.5519 1.75 10 1.75C9.44806 1.75 8.9095 1.8041 8.38909 1.90705L7.65335 2.0526L7.36225 0.581117L8.09799 0.435566ZM14.7946 1.47544L15.4178 1.89268C16.4807 2.6043 17.3957 3.51929 18.1073 4.58222L18.5246 5.20545L17.2781 6.03992L16.8609 5.4167C16.2583 4.5167 15.4833 3.74167 14.5833 3.13914L13.9601 2.7219L14.7946 1.47544ZM6.03993 2.7219L5.4167 3.13914C4.5167 3.74167 3.74167 4.5167 3.13914 5.4167L2.7219 6.03993L1.47545 5.20545L1.89268 4.58222C2.6043 3.51929 3.51929 2.6043 4.58222 1.89269L5.20545 1.47545L6.03993 2.7219ZM2.0526 7.65335L1.90705 8.38909C1.8041 8.9095 1.75 9.44806 1.75 10C1.75 10.5519 1.8041 11.0905 1.90705 11.6109L2.0526 12.3466L0.581117 12.6378L0.435566 11.902C0.313749 11.2862 0.25 10.6502 0.25 10C0.25 9.3498 0.313749 8.71375 0.435566 8.09799L0.581117 7.36225L2.0526 7.65335ZM19.4189 7.36225L19.5644 8.09799C19.6863 8.71375 19.75 9.3498 19.75 10C19.75 10.6502 19.6863 11.2862 19.5644 11.902L19.4189 12.6377L17.9474 12.3466L18.093 11.6109C18.1959 11.0905 18.25 10.5519 18.25 10C18.25 9.44806 18.1959 8.9095 18.093 8.38909L17.9474 7.65335L19.4189 7.36225ZM18.5246 14.7946L18.1073 15.4178C17.3957 16.4807 16.4807 17.3957 15.4178 18.1073L14.7946 18.5246L13.9601 17.2781L14.5833 16.8609C15.4833 16.2583 16.2583 15.4833 16.8609 14.5833L17.2781 13.9601L18.5246 14.7946ZM2.7219 13.9601L3.13914 14.5833C3.74167 15.4833 4.5167 16.2583 5.4167 16.8609L6.03993 17.2781L5.20545 18.5246L4.58222 18.1073C3.51929 17.3957 2.6043 16.4807 1.89269 15.4178L1.47545 14.7946L2.7219 13.9601ZM7.65335 17.9474L8.38909 18.093C8.9095 18.1959 9.44806 18.25 10 18.25C10.5519 18.25 11.0905 18.1959 11.6109 18.093L12.3466 17.9474L12.6378 19.4189L11.902 19.5644C11.2862 19.6863 10.6502 19.75 10 19.75C9.3498 19.75 8.71375 19.6863 8.09799 19.5644L7.36225 19.4189L7.65335 17.9474Z"
      fill="#191919"
    />
  </svg>
);
