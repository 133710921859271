import { HttpClient } from 'lib';
import {
  AddCartPositionResponseModel,
  DeliveryTypeCode,
  GetCartResponseModel,
  ShippingType,
} from 'modules/cart/api';
import { CartTransport } from '../api';
import { TDeliveryPointRequest } from '../api/models/delivery-points-request.model';
import { DeliveryPointSearch } from '../api/models/delivery-points-response.model';

/**
 * Сервис работы с корзиной
 */
class CartService {
  private transport: CartTransport;

  constructor(httpClient: HttpClient) {
    this.transport = new CartTransport(httpClient);
  }

  /**
   * Удалить позицию из корзины
   */
  async deletePosition(id: number): Promise<GetCartResponseModel> {
    await this.removePositon(id);
    return await this.getCart();
  }

  /**
   * Оправить запрос на получение основных данных корзины(не включены данные о доставке)
   */
  getCart(): Promise<GetCartResponseModel> {
    return this.transport.getCart();
  }

  /**
   * Отправить запрос на добавление позиции в корзину
   */
  addPosition(
    offerId: number,
    quantity: number,
  ): Promise<AddCartPositionResponseModel> {
    const formData = new FormData();
    formData.append('cart[0][offer_id]', String(offerId));
    formData.append('cart[0][quantity]', String(quantity));

    return this.transport.addPosition(formData);
  }

  /**
   * Добавить купон к корзине
   */
  addCoupon(coupon: string): Promise<GetCartResponseModel> {
    const formData = new FormData();
    formData.append('coupon', coupon);

    return this.transport.addCoupon(formData);
  }

  /**
   * Удалить купон из корзины
   */
  removeCoupon(coupon: string): Promise<GetCartResponseModel> {
    const formData = new FormData();
    formData.append('coupon', coupon);

    return this.transport.removeCoupon(formData);
  }

  /**
   * Отправить запрос на удаление позиции из корзины
   */
  removePositon(offerId: number): Promise<void> {
    const formData = new FormData();
    formData.append('cart[0][offer_id]', String(offerId));

    return this.transport.removePosition(formData);
  }

  /**
   * Отправить запрос на обновление позиции в корзине
   */
  updatePosition(
    offerId: number,
    quantity: number,
  ): Promise<GetCartResponseModel> {
    const formData = new FormData();
    formData.append('cart[0][offer_id]', String(offerId));
    formData.append('cart[0][quantity]', String(quantity));

    return this.transport.updatePosition(formData);
  }

  getDeliveryTypes(): Promise<Array<ShippingType>> {
    return this.transport.getShippingTypeList();
  }

  getAvailableDeliveryTypes({
    deliveryTypes,
    isFreeDelivery,
    isEkaterinburg,
    isSverdlovskayaOblast,
    isCompany,
  }: {
    deliveryTypes: Array<ShippingType>;
    isFreeDelivery: boolean;
    isEkaterinburg: boolean;
    isSverdlovskayaOblast: boolean;
    isCompany: boolean;
  }): Array<ShippingType> {
    if (isCompany) {
      return deliveryTypes.filter((item: ShippingType) =>
        [
          DeliveryTypeCode.COURIER_COMPANY,
          DeliveryTypeCode.PVZ_COMPANY,
        ].includes(item.code),
      );
    }

    const target: Array<ShippingType> = deliveryTypes.filter(
      (item: ShippingType) => item.code === DeliveryTypeCode.FOR_STAFF,
    );

    if (isFreeDelivery) {
      return target.concat(
        deliveryTypes.filter((item: ShippingType) =>
          [DeliveryTypeCode.FREE, DeliveryTypeCode.FREE_PVZ].includes(
            item.code,
          ),
        ),
      );
    }

    if (isEkaterinburg || isSverdlovskayaOblast) {
      return target.concat(
        deliveryTypes.filter((item: ShippingType) =>
          [DeliveryTypeCode.PVZ, DeliveryTypeCode.COURIER].includes(item.code),
        ),
      );
    }

    return target.concat(
      deliveryTypes.filter((item: ShippingType) =>
        [DeliveryTypeCode.PVZ_RUSSIA, DeliveryTypeCode.COURIER_RUSSIA].includes(
          item.code,
        ),
      ),
    );
  }

  getPvzPoints(params: TDeliveryPointRequest): Promise<DeliveryPointSearch> {
    return this.transport.getPvzPoints(params);
  }
}

export const cartService = new CartService(new HttpClient());
