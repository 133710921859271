'use client';
import { Button } from 'components/ui-kit-v2';
import { TButtonProps } from 'components/ui-kit-v2/button/button.component';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { PropsWithChildren, type JSX } from 'react';
import { useBroadcast } from '../../../hooks/use-broadcast';
import { EOAuthType, TOCHKA_EMPLOYEE_LINK } from '../oauth/oauth.const';

type TTochkaEmployeeSigninButtonProps = {
  onSuccess?: VoidFunction;
  size?: TButtonProps['size'];
};
export const TochkaEmployeeSigninButton = ({
  onSuccess,
  children,
  size = 's',
}: PropsWithChildren<TTochkaEmployeeSigninButtonProps>): JSX.Element => {
  const { invalidateProfile } = useUserInfoContext();

  useBroadcast(EOAuthType.TOCHKA_EMPLOYEE, () => {
    void invalidateProfile().then(() => {
      onSuccess?.();
    });
  });

  const handleClick = (): void => {
    window.open(TOCHKA_EMPLOYEE_LINK);
  };

  return (
    <Button
      type="button"
      isBlock
      variant="brand"
      onClick={handleClick}
      size={size}
    >
      {children ?? 'Войти как сотрудник Точки'}
    </Button>
  );
};
