import { ImageModel, OfferModel } from 'lib';
import { JsonName, JsonArray, JsonStruct, JsonNameReadonly } from 'tserialize';

// TODO: добавить JSDOC моделям после описания их в swagger
export class CartProductModel {
  @JsonStruct(ImageModel, 'preview_image')
  previewImage!: ImageModel | null;

  @JsonName('category_name') categoryName!: string;

  @JsonArray(OfferModel) offers: Array<OfferModel> = [];

  @JsonName() thumbnail!: string;

  @JsonName('secondary_thumb') secondaryThumb!: string;

  @JsonName('updated_at') updatedAt!: string | null;

  @JsonName('created_at') createdAt!: string | null;

  @JsonName() bestseller!: boolean;

  /** Флаг: предзаказ */
  @JsonNameReadonly('pre_order') isPreOrder!: boolean;

  @JsonName() id!: string;

  @JsonName() name!: string;

  @JsonName() code!: string;

  @JsonName() slug!: string;

  @JsonName('category_id') categoryId!: string | null;

  @JsonName('preview_text') previewText!: string | null;
}
