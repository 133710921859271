import { DeliveryTypeCode, ShippingType } from 'modules/cart/api';

const employeeSortingWeight: Record<DeliveryTypeCode, number> = {
  [DeliveryTypeCode.FOR_STAFF]: 0,
  [DeliveryTypeCode.FREE]: 1,
  [DeliveryTypeCode.FREE_PVZ]: 2,
  [DeliveryTypeCode.COURIER]: 3,
  [DeliveryTypeCode.COURIER_RUSSIA]: 4,
  [DeliveryTypeCode.PVZ_RUSSIA]: 5,
  [DeliveryTypeCode.PVZ]: 6,
  [DeliveryTypeCode.COURIER_COMPANY]: 7,
  [DeliveryTypeCode.PVZ_COMPANY]: 8,
};

const defaultSortingWeight: Record<DeliveryTypeCode, number> = {
  [DeliveryTypeCode.FREE]: 1,
  [DeliveryTypeCode.FREE_PVZ]: 2,
  [DeliveryTypeCode.COURIER]: 3,
  [DeliveryTypeCode.COURIER_RUSSIA]: 4,
  [DeliveryTypeCode.PVZ_RUSSIA]: 5,
  [DeliveryTypeCode.PVZ]: 6,
  [DeliveryTypeCode.FOR_STAFF]: 7,
  [DeliveryTypeCode.COURIER_COMPANY]: 8,
  [DeliveryTypeCode.PVZ_COMPANY]: 9,
};

export const sortDelivery = (
  deliveries: Array<ShippingType>,
  { isEmployee }: { isEmployee?: boolean },
): Array<ShippingType> => {
  return deliveries
    .slice()
    .sort((deliveryA: ShippingType, deliveryB: ShippingType) => {
      if (isEmployee) {
        return (
          employeeSortingWeight[deliveryA.code] -
          employeeSortingWeight[deliveryB.code]
        );
      }
      return (
        defaultSortingWeight[deliveryA.code] -
        defaultSortingWeight[deliveryB.code]
      );
    });
};
