import { HttpClient } from 'lib';
import {
  OrderCreateRequest,
  OrderCreateResponse,
  OrderPaymentInfoRequest,
  OrderTransport,
  TOrderCreateRequest,
} from '../api';

class OrderService {
  private transport: OrderTransport;

  constructor(httpClient: HttpClient) {
    this.transport = new OrderTransport(httpClient);
  }

  /**
   * Создать заказ
   */
  create(
    data: TOrderCreateRequest,
  ): Promise<OrderCreateResponse | undefined | void> {
    if (!data.invoiceEmail) {
      delete data.invoiceEmail;
    }

    const model = new OrderCreateRequest(data).toServer();

    const formData = new FormData();

    for (const key in model) {
      if (Object.hasOwn(model, key)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        formData.append(key, model[key]);
      }
    }

    return this.transport.create(formData);
  }

  /**
   * Получить информацию для оплаты
   */
  paymentInfo(
    orderId: number,
  ): Promise<OrderCreateResponse | undefined | void> {
    const model = new OrderPaymentInfoRequest({ orderId }).toServer();

    return this.transport.paymentInfo(model);
  }
}

export const orderService = new OrderService(new HttpClient());
