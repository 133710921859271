import { usePathname, useRouter } from 'next/navigation';
import { EAuthScreen, QUERY_PARAM_SCREEN } from './auth-modal.const';

export const useAuthRoutes = (): {
  show: (route?: EAuthScreen) => void;
  close: VoidFunction;
} => {
  const router = useRouter();
  const pathname = usePathname();
  const show = (route: EAuthScreen = EAuthScreen.LOGIN): void => {
    const params = new URLSearchParams(location.search);
    params.set(QUERY_PARAM_SCREEN, route);
    void router.push(`${pathname}?${params.toString()}`);
  };
  const close = (): void => void router.push(pathname);

  return { show, close };
};
