import { FeatureFlag } from 'components/feature-flag';
import {
  Column,
  InputField,
  RadioButtonField,
  Row,
  Spacer,
  TextareaField,
  Typo,
} from 'components/ui-kit-v2';
import { useFormikContext } from 'formik';
import { getAuthLink } from 'modules/auth';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { PAYMENT_METHOD } from 'modules/cart/api';
import { useRouter } from 'next/navigation';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { useEffect, type JSX } from 'react';
import { Company } from './company.component';
import { TOrderFormValues } from '../order-form.const';

export const Personal = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<TOrderFormValues>();
  const { profile } = useUserInfoContext();
  const router = useRouter();

  useEffect(() => {
    if (!profile?.isEmployee && values?.email?.endsWith('tochka.com')) {
      router.push('?' + getAuthLink(EAuthScreen.LOOKS_LIKE_EMPLOYEE).query);
    }
  }, [values.email, profile, router]);

  return (
    <>
      <Typo size="l" weight="medium">
        Личные данные
      </Typo>
      <Spacer size="3x" />
      <FeatureFlag flag="company_payment">
        <>
          <Row gap="0x">
            <Column span={6}>
              <RadioButtonField
                name="type"
                value="person"
                checked
                isBlock
                label="Физлицо"
                onChange={() =>
                  setFieldValue('paymentMethodId', PAYMENT_METHOD.UKASSA)
                }
              />
            </Column>
            <Column span={6}>
              <RadioButtonField
                name="type"
                value="company"
                isBlock
                label="Компания или ИП"
                onChange={() =>
                  setFieldValue('paymentMethodId', PAYMENT_METHOD.COMPANY)
                }
              />
            </Column>
          </Row>
          <div hidden={values?.type !== 'company'}>
            <Company />
          </div>
        </>
      </FeatureFlag>
      <Row gap="0x" className="mt-3">
        <Column span={{ s: 12, m: 6 }}>
          <InputField name="name" label="Имя" fitHeight />
        </Column>
        <Column span={{ s: 12, m: 6 }}>
          <InputField name="lastName" label="Фамилия" fitHeight />
        </Column>
        <Column span={{ s: 12, m: 6 }}>
          <InputField name="email" label="Email" fitHeight />
        </Column>
        <Column span={{ s: 12, m: 6 }}>
          <InputField
            name="phone"
            label="Телефон"
            mask="+7(999)999-99-99"
            fitHeight
          />
        </Column>

        {values?.type === 'company' && (
          <Column span={12}>
            <InputField
              name="invoiceEmail"
              label="Email в копию тому, кто проведёт оплату по счету. Это необязательное поле"
              fitHeight
            />
          </Column>
        )}

        <Column span={12}>
          <TextareaField
            name="comment"
            label="Комментарий"
            minRows={5}
            maxLength={300}
          />
        </Column>
      </Row>
      <Spacer size="3x" />
    </>
  );
};
