import {
  Alert,
  Box,
  Skeleton,
  SkeletonList,
  Spacer,
  Typo,
} from 'components/ui-kit-v2';
import { useFormikContext } from 'formik';
import { ShippingType } from 'modules/cart/api';
import { DeliveryPoint } from 'modules/cart/api/models/delivery-points-response.model';
import { useCartContext } from 'modules/cart/providers';
import { cartService } from 'modules/cart/services';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { Fragment, useEffect, useMemo, useState, type JSX } from 'react';
import useSWR from 'swr';
import { CourierRadioInput } from './components/courier-radio-input';
import { PvzModal } from './components/pvz-modal/pvz-modal';
import { PvzRadioInput } from './components/pvz-radio-input';
import { StuffRadioInput } from './components/stuff-radio-input';
import { sortDelivery } from './delivery.const';
import { useDeliveryTypeContext } from '../../delivery-price-provider.component';
import {
  EKATERINBURG,
  FREE_DELIVERY_PRICE_TRIGGER,
  SVERDLOVSKAYA_OBLAST,
  TOrderFormValues,
} from '../../order-form.const';

export const Delivery = (): JSX.Element => {
  const { cart } = useCartContext();
  const [isOpenMap, setIsOpenMap] = useState(false);
  const { profile } = useUserInfoContext();
  const { values, errors, touched, setFieldValue } =
    useFormikContext<TOrderFormValues>();
  const { setDelivery } = useDeliveryTypeContext();
  const { data: deliveryTypes = [], isLoading } = useSWR(
    cartService.getDeliveryTypes.name,
    () => cartService.getDeliveryTypes(),
  );

  const isFreeDelivery =
    (cart?.priceForDelivery ?? 0) >= FREE_DELIVERY_PRICE_TRIGGER;
  const isEkaterinburg = values.shippingAddress.addressFull
    .toLowerCase()
    .includes(EKATERINBURG);
  const isSverdlovskayaOblast = values.shippingAddress.addressFull
    .toLowerCase()
    .includes(SVERDLOVSKAYA_OBLAST);

  const availableDeliveries = useMemo(
    () =>
      sortDelivery(
        cartService.getAvailableDeliveryTypes({
          deliveryTypes,
          isEkaterinburg,
          isFreeDelivery,
          isSverdlovskayaOblast,
          isCompany: values?.type === 'company',
        }),
        {
          isEmployee: profile?.isEmployee,
        },
      ),
    [
      deliveryTypes,
      isEkaterinburg,
      isFreeDelivery,
      isSverdlovskayaOblast,
      profile?.isEmployee,
      values?.type,
    ],
  );

  useEffect(() => {
    setFieldValue('shippingTypeCode', availableDeliveries[0]?.code);
  }, [availableDeliveries, setFieldValue, values.type]);

  return (
    <>
      <Typo size="l" weight="medium">
        Способ
      </Typo>
      <Spacer size="3x" />
      <PvzModal
        isOpen={isOpenMap}
        onClose={() => setIsOpenMap(false)}
        onSelect={(delivery: DeliveryPoint) => {
          setFieldValue('deliveryPoint', delivery);
        }}
      />

      {isLoading ? (
        <SkeletonList
          count={3}
          renderItem={
            <Box className="px-4 py-4">
              <Skeleton width="30%" />
              <Skeleton width="70%" />
            </Box>
          }
        />
      ) : (
        availableDeliveries.map((delivery: ShippingType) => (
          <Fragment key={delivery.id}>
            <PvzRadioInput
              delivery={delivery}
              onShowMap={() => setIsOpenMap(true)}
              onChange={() => setDelivery(delivery)}
            />
            <CourierRadioInput
              delivery={delivery}
              onChange={() => setDelivery(delivery)}
            />
            <StuffRadioInput
              delivery={delivery}
              onChange={() => setDelivery(delivery)}
            />
          </Fragment>
        ))
      )}
      <Alert
        className="my-4"
        visible={Boolean(touched.shippingTypeCode && errors.shippingTypeCode)}
      >
        {errors.shippingTypeCode}
      </Alert>
    </>
  );
};
