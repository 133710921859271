import { Clickable } from 'components/ui-kit-v2';
import { Column, Row } from 'components/ui-kit-v2/grid';
import { IcArrowLeftIcon } from 'components/ui-kit-v2/icon-v2/icon-libs/ic-arrow-left';
import { Typo } from 'components/ui-kit-v2/typo';
import { useHistoryBack } from 'hooks';
import { type JSX } from 'react';

export type TSubHeaderProps = {
  title: string;
  fallbackBackHistory?: string;
};
export const SubHeader = ({
  title,
  fallbackBackHistory,
}: TSubHeaderProps): JSX.Element => {
  const back = useHistoryBack(fallbackBackHistory);

  return (
    <Clickable onClick={back}>
      <Row align="center" gap="4x" className="py-5 d-inline-flex">
        <Column span="auto" align="flex-end">
          <IcArrowLeftIcon />
        </Column>
        <Column span="auto">
          <Typo size={{ s: 'l', m: '2xl' }} type="h2">
            {title}
          </Typo>{' '}
        </Column>
      </Row>
    </Clickable>
  );
};
