import { deserialize, JsonName, JsonStruct } from 'tserialize';

class ConformationData {
  /** Флаг принудительно TODO: обновить описание */
  @JsonName() enforce!: boolean;

  /** Ссылка на страницу оплаты */
  @JsonName('confirmation_url') confirmationUrl!: string;

  /** Тип */
  @JsonName() type!: string;
}

export class OrderCreateResponse {
  /** Id оплаты */
  @JsonName() id!: string;

  /** Статус */
  @JsonName() status!: string;

  /** Описание */
  @JsonName() description!: string;

  /** Время создания */
  @JsonName('created_at') createdAt!: string;

  /** Информация для оплаты через Ю-кассу */
  @JsonStruct(ConformationData) confirmation!: ConformationData;

  /** Флан оплачено */
  @JsonName() paid!: false;

  /** Флаг возвращемо */
  @JsonName() refundable!: false;

  /** Флаг тест */
  @JsonName() test!: boolean;

  static fromServer(data: object | null): OrderCreateResponse | undefined {
    if (data) return deserialize(data, this);
  }
}
