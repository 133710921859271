import { Field, FieldProps } from 'formik';
import { type JSX } from 'react';
import { ITextareaProps, Textarea } from './textarea.component';

export const TextareaField = (props: ITextareaProps): JSX.Element => (
  <Field name={props.name}>
    {({ field, meta }: FieldProps<string>) => (
      <Textarea
        {...props}
        {...field}
        errorMessage={(meta.touched && meta.error) || undefined}
      />
    )}
  </Field>
);
