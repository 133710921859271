import cn from 'classnames';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
} from 'react';
import { TColor } from 'styles/theme';
import styles from './box.module.scss';

type TBoxProps = {
  className?: string;
  style?: CSSProperties;
  variant?: 'outline' | 'flat';
  backgroundColor?: TColor | 'transparent';
} & PropsWithChildren;

export const Box = forwardRef<HTMLDivElement | null, TBoxProps>(
  (
    {
      className,
      variant = 'outline',
      backgroundColor = 'Primitive-Primary-On-Inverse',
      children,
      style,
    }: TBoxProps,
    ref: ForwardedRef<HTMLDivElement | null>,
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={cn(
          styles[`variant-${variant}`],
          styles[`background-color-${backgroundColor}`],
          className,
        )}
      >
        {children}
      </div>
    );
  },
);
