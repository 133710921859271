'use client';
import { useScrollLock } from '@brand/utils';
import cn from 'classnames';
import { type JSX } from 'react';
import ReactModal from 'react-modal';
import { TModalProps } from './modal.component';
import styles from './modal.module.scss';
import { Box } from '../box';
import { Row } from '../grid';

export const ModalDesktop = ({
  children,
  onClose,
  isOpen,
  className,
  contentClassName,
}: TModalProps): JSX.Element | null => {
  useScrollLock(isOpen);

  if (!isOpen) {
    return null;
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={styles.overlay}
      className={cn(styles.container, className)}
    >
      <Box variant="outline" className={cn(styles.content, contentClassName)}>
        {children}
      </Box>
    </ReactModal>
  );
};
