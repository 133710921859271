import { DeliveryType } from 'modules/cart/api/models/delivery-points-response.model';
import { MutableRefObject } from 'react';
import type ymaps from 'yandex-maps';
import { Clusterer } from './pvz-map.types';

export const DEFAULT_MAP_ZOOM = 14;
export const ON_POINT_CHOOSE_MAP_ZOOM = 18;
export const defaultState = {
  center: [56.83645804984439, 60.58863674643593],
  zoom: DEFAULT_MAP_ZOOM,
};

const ICON_SIZE = 30;
export const getPlacemarkIcon = (type: DeliveryType): string =>
  `<img style="height: ${ICON_SIZE}px; width: ${ICON_SIZE}px;" src="/images/${type}_logo.svg"/>`;

const ClustererIconColor: Record<DeliveryType, string> = {
  boxberry: '#ED1651',
  cdek: '#1AB248',
  pony: '#1AB248',
};

const getClustererIcon = (type: DeliveryType): string =>
  `<div class="clusterIcon" style="display: flex; justify-content: center;align-items: center;background: #fff; width: ${ICON_SIZE}px; height: ${ICON_SIZE}px; border: 4px solid ${ClustererIconColor[type]};">{{ properties.geoObjects.length }}</div>`;

export function getDeliveryIcons(
  type: DeliveryType,
  api: typeof ymaps,
): {
  clustererConfigure: (ref: Clusterer | null) => void;
  placeMarkOptions: {
    iconLayout: ymaps.IClassConstructor<ymaps.layout.templateBased.Base>;
    iconShape: { type: string; coordinates: Array<Array<number>> };
  };
} {
  return {
    clustererConfigure: (ref: Clusterer | null) => {
      ref?.options.set(
        'clusterIconLayout',
        api.templateLayoutFactory.createClass(getClustererIcon(type)),
      );
      ref?.options.set('clusterIconShape', {
        type: 'Rectangle',
        coordinates: [
          [0, 0],
          [ICON_SIZE, ICON_SIZE],
        ],
      });
    },
    placeMarkOptions: {
      iconLayout: api.templateLayoutFactory.createClass(
        getPlacemarkIcon(type),
        {},
      ),
      iconShape: {
        type: 'Rectangle',
        coordinates: [
          [0, 0],
          [ICON_SIZE, ICON_SIZE],
        ],
      },
    },
  };
}

export const mergeRefs = <T>(...refs: Array<MutableRefObject<T>>) => {
  return (node: T) => {
    for (const ref of refs) {
      ref.current = node;
    }
  };
};
