'use client';
import { Alert, Button, Column, Row, Spacer } from 'components/ui-kit-v2';
import { InputPasswordField } from 'components/ui-kit-v2/form/input-password/input-pasword.field';
import { Form, Formik, FormikProps } from 'formik';
import { authService } from 'lib';
import { useSearchParams } from 'next/navigation';
import { useState, type JSX } from 'react';
import * as yup from 'yup';

type TPasswordChangeValues = {
  password: string;
  passwordConfirm: string;
};

const INITIAL_VALUES: TPasswordChangeValues = {
  password: '',
  passwordConfirm: '',
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'В пароле должно быть хотя бы 8 знаков')
    .max(40, 'Пароль должен быть не больше 40 символов!')
    .required('Это обязательное поле'),
  passwordConfirm: yup
    .string()
    .required('Это обязательное поле')
    .oneOf([yup.ref('password'), null], 'Пароли не совпали, попробуйте ещё'),
});
type TRestorePasswordFormProps = {
  onSuccess: VoidFunction;
};
export const RestorePasswordForm = ({
  onSuccess,
}: TRestorePasswordFormProps): JSX.Element | null => {
  const [errorMessage, setErrorMessage] = useState('');
  const code = useSearchParams().get('code');

  if (!code) {
    return null;
  }

  const handleLogin = async (values: TPasswordChangeValues): Promise<void> => {
    setErrorMessage('');

    await authService
      .resetPassword(code, values.password, values.passwordConfirm)
      .then(onSuccess)
      .catch((error: unknown) => setErrorMessage((error as Error).message));
  };
  return (
    <Formik<TPasswordChangeValues>
      validationSchema={validationSchema}
      onSubmit={handleLogin}
      initialValues={INITIAL_VALUES}
    >
      {({ isSubmitting, isValid }: FormikProps<TPasswordChangeValues>) => (
        <Form placeholder="">
          <Alert visible={Boolean(errorMessage)} className="mb-4">
            {errorMessage}
          </Alert>

          <Row gap={['2x', '0x']}>
            <Column span={12}>
              <InputPasswordField name="password" label="Новый пароль" />
            </Column>
            <Column span={12}>
              <InputPasswordField
                name="passwordConfirm"
                label="Повторите новый пароль"
              />
            </Column>
          </Row>

          <Spacer size="16x" />
          <Row>
            <Column span={12}>
              <Button isBlock isDisabled={isSubmitting || !isValid}>
                Обновить пароль
              </Button>
            </Column>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
