import { Clickable, Column, Icon, Row, Typo } from 'components/ui-kit-v2';
import { useFormikContext } from 'formik';
import { type JSX } from 'react';
import { TOrderFormValues } from '../../../order-form.const';

export type TPvzAccessoriesProps = {
  onClick: VoidFunction;
};

export function PvzAccessories({ onClick }: TPvzAccessoriesProps): JSX.Element {
  const { values, errors, touched } = useFormikContext<TOrderFormValues>();

  if (!values.deliveryPoint) {
    const hasError = touched.deliveryPoint && errors?.deliveryPoint;
    return (
      <Row justify="space-between">
        <Column>
          <Clickable onClick={onClick}>
            <Typo
              size="s"
              color={hasError ? 'Primitive-Error' : undefined}
              className="d-flex align-items-center"
            >
              {hasError ? <Icon icon="Warning" /> : null}
              <span className={hasError ? 'mx-4' : undefined}>
                Выбрать пункт выдачи
              </span>
            </Typo>
          </Clickable>
        </Column>
        <Column>
          <Clickable onClick={onClick}>
            <Icon
              icon="IcArrowRight"
              color={hasError ? 'Primitive-Error' : undefined}
            />
          </Clickable>
        </Column>
      </Row>
    );
  }

  return (
    <Row justify="space-between">
      <Column>
        <Typo type="div" size="s" color="Primitive-Secondary">
          Пункт выдачи {values.deliveryPoint?.name}
        </Typo>
        <Typo type="div" size="s">
          {values.deliveryPoint?.address}
        </Typo>
      </Column>
      <Column>
        <Clickable onClick={onClick}>
          <Typo size="s">Изменить</Typo>
        </Clickable>
      </Column>
    </Row>
  );
}
