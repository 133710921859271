import { IRadioButtonProps, RadioButton } from 'components/ui-kit-v2';
import { Field, FieldProps } from 'formik';
import { ChangeEvent, type JSX } from 'react';

export const RadioButtonField = (props: IRadioButtonProps): JSX.Element => {
  return (
    <Field name={props.name} value={props.value} type="radio">
      {({ field }: FieldProps<string>) => (
        <RadioButton
          {...props}
          {...field}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            props.onChange?.(event);
            field.onChange?.(event);
          }}
          type="radio"
        />
      )}
    </Field>
  );
};
