import { type JSX, createElement } from 'react';
import { TColor } from 'styles/theme';
// eslint-disable-next-line import/namespace
import * as icons from './icon-libs';

export type TIconProps = {
  /**
   * Цвет иконки
   */
  color?: TColor;
  /**
   * CSS-класс
   */
  className?: string;
};

type TProps = {
  /**
   * Название иконки
   * согласно названию компонентов в /icon-libs
   */
  icon: keyof typeof icons;
} & TIconProps;

export const Icon = ({ icon, ...props }: TProps): JSX.Element =>
  createElement(icons[icon], props);
