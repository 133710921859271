import cn from 'classnames';
import { ProductPrice } from 'components/product-price';
import { SimpleImage } from 'components/simple-image';
import { Column, Link, Row, Typo } from 'components/ui-kit-v2';
import { MoneyFormatter, OfferPrice } from 'lib';
import { Currency } from 'lib/formatters/money.formatter';
import { CartPositions } from 'modules/cart/api';
import { type JSX } from 'react';

type TCartPositionItem = {
  position: CartPositions;
};
export function CartPositionItem(props: TCartPositionItem): JSX.Element {
  const { position } = props;
  const { product, offer } = position;
  return (
    <Row>
      <Column span={{ s: 4, m: 3 }}>
        <Link
          aria-label={product.name}
          href={`/product/${product.slug}`}
          className="aspect-ratio-10-11 d-block position-relative"
        >
          <SimpleImage
            fill
            objectPosition="top"
            alt={product.name}
            src={product.previewImage?.src}
          />
        </Link>
      </Column>

      <Column span={{ s: 8, m: 9 }}>
        <Row className="h-100" direction="column" justify="space-between">
          <Column>
            <Link href={`/product/${product.slug}`}>
              <Typo>{product.name}</Typo>
            </Link>
            <div className="pt-2">
              <ProductPrice offer={offer}>
                {({
                  priceValue,
                  oldPriceValue,
                  isPriceChanged,
                }: OfferPrice) => {
                  return (
                    <>
                      {isPriceChanged ? (
                        <Row align="center">
                          <Column>
                            <Typo weight="medium" type="span">
                              {MoneyFormatter.format(priceValue, {
                                currency: Currency.RUB,
                              })}
                            </Typo>
                          </Column>
                          <Column>
                            <Typo
                              type="div"
                              decoration="line-through"
                              size="xs"
                              className={cn('opacity-60', 'mx-2')}
                            >
                              {MoneyFormatter.format(oldPriceValue, {
                                currency: Currency.RUB,
                              })}
                            </Typo>
                          </Column>
                        </Row>
                      ) : (
                        <Typo weight="medium">
                          {MoneyFormatter.format(priceValue, {
                            currency: Currency.RUB,
                          })}
                        </Typo>
                      )}
                    </>
                  );
                }}
              </ProductPrice>
            </div>
          </Column>
        </Row>
      </Column>
    </Row>
  );
}
