import { JsonName, deserialize, serialize } from 'tserialize';

export class Country {
  @JsonName() a1!: string;

  @JsonName() a2!: string;

  @JsonName() code!: string;

  @JsonName('english_name') englishName!: number;

  @JsonName() name!: string;

  static fromServer(data: unknown): Country {
    return deserialize(data, this);
  }

  toServer(): Country {
    return serialize(this) as Country;
  }
}

export type TCountry = Omit<Country, 'toServer'>;
