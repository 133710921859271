import { type JSX } from 'react';
import ReactSkeleton, { type SkeletonProps } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { colors } from 'styles/theme';

export type TSkeletonProps = {
  /**
   * Состояние загрузки активно при children
   */
  isLoading?: boolean;
  children?: JSX.Element;
} & SkeletonProps;

export const Skeleton = ({
  isLoading,
  baseColor,
  width = '100%',
  children,
  ...props
}: TSkeletonProps): JSX.Element => {
  const SkeletonCmp = (
    <ReactSkeleton
      {...props}
      baseColor={colors['Container-Transparent-2']}
      highlightColor={colors['Container-Transparent-1']}
      width={width}
    />
  );

  if (children) {
    return isLoading ? SkeletonCmp : children;
  }

  return SkeletonCmp;
};
