'use client';
import { useQueryRouter } from 'components/query-router';
import { Button, Column, Row, Spacer } from 'components/ui-kit-v2';
import { InputField } from 'components/ui-kit-v2/form/input/input.field';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { authService } from 'lib';
import { EMAIL_REGEXP } from 'lib/validators/validators';
import { useRouter } from 'next/navigation';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { ChangeEvent, useCallback, type JSX } from 'react';
import * as yup from 'yup';
import { EAuthScreen } from '../auth-modal.const';
import { getAuthLink } from '../get-auth-link';

type TForgotPasswordFormProps = {
  onSuccess?: VoidFunction;
};

type TFormValues = {
  email: string;
};

const INITIAL_VALUES: TFormValues = { email: '' };
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Проверьте адрес')
    .matches(EMAIL_REGEXP, 'Проверьте адрес')
    .required('Проверьте адрес'),
});

export const ForgotPasswordForm = ({
  onSuccess,
}: TForgotPasswordFormProps): JSX.Element => {
  const queryRouter = useQueryRouter();
  const { profile } = useUserInfoContext();
  const router = useRouter();

  const handleSubmit = async (
    values: TFormValues,
    helpers: FormikHelpers<TFormValues>,
  ): Promise<void> => {
    try {
      await authService.restorePassword(values.email);
      onSuccess?.();
    } catch {
      helpers.setFieldError('email', 'Такое адрес не регистрировался');
    }
  };

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!profile?.isEmployee && event.target?.value.endsWith('tochka.com')) {
        router.push('?' + getAuthLink(EAuthScreen.LOOKS_LIKE_EMPLOYEE).query);
      }
    },
    [profile?.isEmployee, router],
  );

  return (
    <Formik<TFormValues>
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      initialValues={INITIAL_VALUES}
    >
      {({ isSubmitting, isValid }: FormikProps<TFormValues>) => (
        <Form placeholder="">
          <Row gap={['2x', '2x']}>
            <Column span={12}>
              <InputField name="email" label="Email" onChange={handleChange} />
            </Column>
          </Row>
          <Spacer size="16x" />
          <Row gap={['2x', '2x']}>
            <Column span={12}>
              <Button isBlock isLoading={isSubmitting} isDisabled={!isValid}>
                Продолжить
              </Button>
            </Column>
            <Column span={12}>
              <Button
                isBlock
                variant="ghost"
                onClick={() => queryRouter.push(EAuthScreen.SIGNUP)}
              >
                Создать личный кабинет
              </Button>
            </Column>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
