import {
  HttpClient,
  HttpClientResponse,
  NetworkError,
  isErrorWithMessage,
} from 'lib';
import { OrderCreateResponse, TOrderPaymentInfoRequest } from '../models';

export class OrderTransport {
  constructor(private httpClient: HttpClient) {}

  /**
   * Создать заказ
   */
  async create(data: FormData): Promise<OrderCreateResponse | undefined> {
    try {
      const response = await this.httpClient.post<HttpClientResponse<object>>(
        '/orders/create/',
        data,
      );

      if (!response.data.status) {
        throw new NetworkError(
          response.data.message
            ? `Не удалось создать заказ: ${response.data.message}`
            : 'Не удалось создать заказ',
        );
      }

      return OrderCreateResponse.fromServer(response.data.data);
    } catch (error: unknown) {
      throw new NetworkError(
        isErrorWithMessage(error) ? error.message : 'Не удалось создать заказ',
      );
    }
  }

  /**
   * Получить информацию для оплаты
   */
  async paymentInfo(
    data: TOrderPaymentInfoRequest,
  ): Promise<OrderCreateResponse | undefined> {
    try {
      const response = await this.httpClient.post<HttpClientResponse<object>>(
        'orders/payment',
        data,
      );

      return OrderCreateResponse.fromServer(response.data.data);
    } catch (error: unknown) {
      throw new NetworkError(
        isErrorWithMessage(error)
          ? error.message
          : 'Не удалось получить ссылку на оплату',
      );
    }
  }
}
