import cn from 'classnames';
import { SlideUp, Spacer, Typo } from 'components/ui-kit-v2';
import { type JSX } from 'react';
import styles from './radio-input.module.scss';
import { Label } from '../label';
import { TLabelProps } from '../label/label.types';

export interface IRadioInputProps
  extends Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'formAction' | 'children' | 'size'
    >,
    Omit<
      TLabelProps,
      'children' | 'isFocused' | 'leftAccessory' | 'description'
    > {
  /**
   * Маска
   */
  bottomAccessory?: JSX.Element;
  description?: string | JSX.Element;
}

export const RadioInput = ({
  label,
  tooltip,
  description,
  errorMessage,
  bottomAccessory,
  rightAccessory,
  className,
  disabled,
  size = 'm',
  fitHeight,
  ...inputProps
}: IRadioInputProps): JSX.Element => {
  const children = (
    <div className={cn(styles.content)}>
      <div className={styles.contentInner}>
        <input
          type="radio"
          {...inputProps}
          disabled={disabled}
          name={inputProps.name}
          className={styles.radio}
        />
        <div
          className={cn(styles.textWrapper, {
            [styles.textWrapperHasRightAccessory]: !!rightAccessory,
          })}
        >
          <Typo size="s">{label}</Typo>
          <Spacer size="1x" />
          <Typo size="s" color="Primitive-Secondary">
            {description}
          </Typo>
        </div>
        {rightAccessory}
      </div>
      {bottomAccessory && (
        <SlideUp
          isShow={inputProps.checked}
          className={styles.bottomAccessoryWrapper}
        >
          <div className={styles.bottomAccessory}>{bottomAccessory}</div>
        </SlideUp>
      )}
    </div>
  );

  const labelProps: TLabelProps = {
    size,
    tooltip,
    rightAccessory,
    fitHeight,
    children,
    className,
    errorMessage,
  };

  return <Label {...labelProps} />;
};
