import {
  Alert,
  Button,
  CheckboxField,
  Link,
  Spacer,
  Typo,
} from 'components/ui-kit-v2';
import { useFormikContext } from 'formik';
import { type JSX } from 'react';
import { TOrderFormValues } from '../order-form.const';

const DEFAULT_SCROLL_OFFSET = 200;
export const CreateOrder = (): JSX.Element => {
  const { isSubmitting, values, setTouched, errors, isValid, handleSubmit } =
    useFormikContext<TOrderFormValues>();

  const handleClick = (): void => {
    if (!isValid) {
      const allFieldsTouched = Object.keys(values).reduce(
        (acc: Record<string, unknown>, fieldName: string) => {
          acc[fieldName] = true;
          return acc;
        },
        {},
      );
      setTouched({
        ...allFieldsTouched,
        shippingAddress: { addressFull: true },
      });

      return void setTimeout(
        () => window.scrollTo(0, DEFAULT_SCROLL_OFFSET),
        DEFAULT_SCROLL_OFFSET,
      );
    }
    handleSubmit();
  };

  return (
    <>
      <Button
        type="button"
        isBlock
        isLoading={isSubmitting}
        onClick={handleClick}
      >
        Перейти к оплате
      </Button>
      <Alert visible={Boolean(errors.serverError)} className="my-4">
        {errors.serverError}
      </Alert>
      <Spacer size="3x" />
      <CheckboxField
        name="agreement"
        errorMessage="Нужно ваше согласие"
        label={
          <Typo size="xs">
            Соглашаюсь с&nbsp;
            <Link href="/terms">
              <Typo size="xs" decoration="underline">
                условиями публичной оферты
              </Typo>
            </Link>
            ,{' '}
            <Link href="/concent">
              <Typo size="xs" decoration="underline">
                обработки персональных данных
              </Typo>
            </Link>{' '}
            и&nbsp;
            <Link href="/privacy">
              <Typo size="xs" decoration="underline">
                политики конфиденциальности.
              </Typo>
            </Link>
          </Typo>
        }
      />
    </>
  );
};
