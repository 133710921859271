import cn from 'classnames';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { type JSX } from 'react';

type LinkProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof NextLinkProps
> &
  NextLinkProps;

export const Link = ({ className, ...props }: LinkProps): JSX.Element => (
  <NextLink className={cn('clear-link', className)} {...props} />
);
