import { JsonName, serialize } from 'tserialize';

export type TDeliveryPointRequest = Omit<DeliveryPointRequest, 'toServer'>;

export class DeliveryPointRequest {
  @JsonName() id?: number;
  /**
   * Значения точек ширины и долготы видимой область
   */
  @JsonName('bounds[bottom_left_lat]') boundBottomLeftLat!: number;
  @JsonName('bounds[bottom_left_lon]') boundBottomLeftLon!: number;
  @JsonName('bounds[top_right_lat]') boundTopRightLat!: number;
  @JsonName('bounds[top_right_lon]') boundTopRightLon!: number;
  /**
   * Предыдущие значения точек ширины и долготы видимой область
   */
  @JsonName('bounds_prev[bottom_left_lat]') prevBoundBottomLeftLat?: number;
  @JsonName('bounds_prev[bottom_left_lon]') prevBoundBottomLeftLon?: number;
  @JsonName('bounds_prev[top_right_lat]') prevBoundTopRightLat?: number;
  @JsonName('bounds_prev[top_right_lon]') prevBoundTopRightLon?: number;

  /**
   * Список полей в ответе
   */
  @JsonName('fields') fields?: Array<string>;
  /**
   * Службы доставки
   */
  @JsonName('services[]') services?: Array<string>;
  /**
   * Количество элементов на одной странице
   */
  @JsonName('page[size]') pageSize?: number;
  /**
   * Курсор для паджинации
   */
  @JsonName('page[cursor]') pageCursor?: number;

  constructor(data: TDeliveryPointRequest) {
    Object.assign(this, data);
  }

  toServer(): DeliveryPointRequest {
    return serialize(this) as DeliveryPointRequest;
  }
}
