import { CSSProperties, type JSX } from 'react';
import styles from './progress.module.scss';

type TProgressProps = {
  /**
   * Значение прогрессбара от 0 до 100
   */
  progress: number;
};

export const Progress = ({ progress }: TProgressProps): JSX.Element => {
  return (
    <div
      style={{ '--progress': `${progress}%` } as CSSProperties}
      className={styles.container}
    />
  );
};
