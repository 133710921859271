import { IRadioInputProps, RadioInputField, Typo } from 'components/ui-kit-v2';
import { MoneyFormatter } from 'lib';
import { DeliveryTypeCode, ShippingType } from 'modules/cart/api';
import { type JSX } from 'react';
import { PvzAccessories } from './pvz-accessory';

type TPvzRadioInputProps = {
  delivery: ShippingType;
  onShowMap: VoidFunction;
} & IRadioInputProps;

export const PvzRadioInput = ({
  delivery,
  onShowMap,
  ...props
}: TPvzRadioInputProps): JSX.Element | undefined => {
  const description =
    'Заказ поедет из Екатеринбурга, после оформления пришлем трек-номер на ваш Email.';
  if (
    [
      DeliveryTypeCode.PVZ,
      DeliveryTypeCode.PVZ_RUSSIA,
      DeliveryTypeCode.FREE_PVZ,
      DeliveryTypeCode.PVZ_COMPANY,
    ].includes(delivery.code)
  ) {
    return (
      <RadioInputField
        key={delivery.id}
        size="s"
        name="shippingTypeCode"
        label={delivery.name}
        value={delivery.code}
        description={description}
        rightAccessory={
          <Typo>{MoneyFormatter.format(+delivery.priceValue)}</Typo>
        }
        bottomAccessory={<PvzAccessories onClick={onShowMap} />}
        {...props}
      />
    );
  }
};
