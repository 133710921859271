import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcGeo = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7499 3V2.25H11.2499V3V4.28582C7.56842 4.63932 4.63925 7.56848 4.28572 11.25H3H2.25V12.75H3H4.28572C4.63925 16.4315 7.56842 19.3607 11.2499 19.7142V21V21.75H12.7499V21V19.7142C16.4314 19.3606 19.3605 16.4315 19.7141 12.75H21H21.75V11.25H21H19.7141C19.3605 7.56852 16.4314 4.63938 12.7499 4.28583V3ZM11.9999 18.25C15.4517 18.25 18.2499 15.4518 18.2499 12C18.2499 8.54822 15.4517 5.75 11.9999 5.75C8.56032 5.75 5.7697 8.52846 5.75 11.9634V12.0366C5.7697 15.4715 8.56032 18.25 11.9999 18.25ZM11.9999 16C14.2091 16 15.9999 14.2091 15.9999 12C15.9999 9.79086 14.2091 8 11.9999 8C9.79081 8 7.99995 9.79086 7.99995 12C7.99995 14.2091 9.79081 16 11.9999 16Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
