import { useQueryRouter } from 'components/query-router';
import { Clickable, Icon, Typo } from 'components/ui-kit-v2';
import {
  Modal,
  ModalContent,
  ModalHeader,
} from 'components/ui-kit-v2/modal/modal.component';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { type JSX } from 'react';
import styles from '../../auth-modal.module.scss';
import { SignupForm } from '../../components/signup-form.component';

export const SignupRoute = (): JSX.Element => {
  const router = useQueryRouter();
  return (
    <Modal
      onClose={() => router.push('')}
      isOpen={Boolean(router.current === EAuthScreen.SIGNUP)}
      contentClassName={styles.modal}
    >
      <ModalHeader>
        <div className="d-flex">
          <Clickable onClick={router.back}>
            <Icon icon="IcArrowLeftIcon" className="mr-4" />
          </Clickable>
          <Typo size="2xl" className="px-2">
            Создать личный кабинет
          </Typo>
        </div>
      </ModalHeader>
      <ModalContent>
        <SignupForm onSuccess={() => router.push(EAuthScreen.SUCCESS_SIGNUP)} />
      </ModalContent>
    </Modal>
  );
};
