import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcArrowRight = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7725 10.75L11.6362 14.8863L12.6968 15.947L18.1135 10.5303L18.6438 9.99998L18.1135 9.46965L12.6968 4.05298L11.6362 5.11364L15.7725 9.24998H3.4165V10.75H15.7725Z"
      fill={color ? colors[color] : 'currentColor'}
    />
  </svg>
);
