import { Profile } from 'lib';
import { EMAIL_REGEXP, RUSSIAN_PHONE_REGEXP } from 'lib/validators/validators';
import {
  DeliveryTypeCode,
  PAYMENT_METHOD,
  TOrderCreateRequest,
} from 'modules/cart/api';
import * as yup from 'yup';
export const RUSSIA_CODE = 'россия';
export const FREE_DELIVERY_PRICE_TRIGGER = 5000;
export const EKATERINBURG = 'екатеринбург';
export const SVERDLOVSKAYA_OBLAST = 'свердловская область';

export type TOrderFormValues = TOrderCreateRequest & { serverError: string };

export const initialValues: TOrderFormValues = {
  type: 'person',
  agreement: true,
  paymentMethodId: PAYMENT_METHOD.UKASSA,
  shippingTypeCode: '',
  deliveryPoint: null,
  office: null,
  comment: '',
  invoiceEmail: '',
  inn: '',
  email: '',
  lastName: '',
  name: '',
  phone: '',
  serverError: '',
  shippingAddress: {
    address: '',
    addressFull: '',
    city: '',
    country: '',
    flat: '',
    floor: '',
    house: '',
    lat: null,
    lon: null,
    postcode: '',
    state: '',
    street: '',
  },
};

export const validationSchema = yup.object().shape({
  comment: yup.string(),
  serverError: yup.string(),
  agreement: yup.boolean().oneOf([true], 'Нужно ваше согласие'),
  name: yup.string().required('Проверьте имя'),
  lastName: yup.string().required('Проверьте фамилию'),
  email: yup
    .string()
    .email('Проверьте адрес')
    .matches(EMAIL_REGEXP, 'Проверьте адрес')
    .required('Проверьте адрес'),
  invoiceEmail: yup.string().when('type', {
    is: 'company',
    then: yup
      .string()
      .email('Проверьте адрес')
      .matches(EMAIL_REGEXP, 'Проверьте адрес'),
    otherwise: yup.string().notRequired(),
  }),
  phone: yup
    .string()
    .matches(RUSSIAN_PHONE_REGEXP, 'Проверьте номер')
    .required('Проверьте номер'),
  type: yup.string(),
  shippingTypeCode: yup.string().required('Укажите способ доставки'),
  deliveryPoint: yup
    .object()
    .default({})
    .when('shippingTypeCode', {
      is: (shippingTypeCode: DeliveryTypeCode) =>
        [
          DeliveryTypeCode.FREE_PVZ,
          DeliveryTypeCode.PVZ_RUSSIA,
          DeliveryTypeCode.PVZ,
          DeliveryTypeCode.PVZ_COMPANY,
        ].includes(shippingTypeCode),
      then: yup.object().nullable().required(),
      otherwise: yup.object().nullable(),
    }),
  office: yup
    .object()
    .default({})
    .when('shippingTypeCode', {
      is: (shippingTypeCode: DeliveryTypeCode) =>
        [DeliveryTypeCode.FOR_STAFF].includes(shippingTypeCode),
      then: yup.object().nullable().required(),
      otherwise: yup.object().nullable(),
    }),
  shippingAddress: yup.object().when('shippingTypeCode', {
    is: (shippingTypeCode: DeliveryTypeCode) =>
      [
        DeliveryTypeCode.COURIER,
        DeliveryTypeCode.COURIER_RUSSIA,
        DeliveryTypeCode.FREE,
        DeliveryTypeCode.COURIER_COMPANY,
      ].includes(shippingTypeCode),
    then: yup.object().shape({
      address: yup.string(),
      addressFull: yup
        .string()
        .required('Укажите название улицы, номер дома, квартиру или офис.')
        .test('address-exist', 'Выберите адрес из списка', function () {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { address } = this.parent;
          return Boolean(address);
        }),
    }),
    otherwise: yup.object().shape({
      addressFull: yup.string().nullable(),
      address1: yup.string().nullable(),
    }),
  }),
  inn: yup.string().when('type', {
    is: (type: string) => type === 'company',
    then: yup.string().required('Проверьте номер'),
    otherwise: yup.string().nullable(),
  }),
});

export const updateValuesByProfile = (profile: Profile): TOrderFormValues => ({
  ...initialValues,
  name: profile?.firstName ?? initialValues.name,
  lastName: profile?.lastName ?? initialValues.lastName,
  phone: profile?.phone ?? initialValues.phone,
  email: profile?.email ?? initialValues.email,
  shippingAddress: {
    ...initialValues.shippingAddress,
    // country:
    //   profile?.addresses[0]?.country ?? initialValues.shippingAddress.country,
    postcode:
      profile?.addresses[0]?.postcode ?? initialValues.shippingAddress.postcode,
    city: profile?.addresses[0]?.city ?? initialValues.shippingAddress.city,
    state: profile?.addresses[0]?.state ?? initialValues.shippingAddress.state,
    address:
      profile?.addresses[0]?.address1 ?? initialValues.shippingAddress.address,
    addressFull:
      profile?.addresses[0]?.addressFull ??
      initialValues.shippingAddress.addressFull,
  },
});
