'use client';

import { HttpClientError, noopPromise } from '@brand/utils';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { createContext, ReactNode, useContext, type JSX } from 'react';
import useSWR from 'swr';
import { GetCartResponseModel } from '../api';
import { cartService } from '../services';

type TCartContext = {
  cart: GetCartResponseModel | undefined;
  setCart: (cart: GetCartResponseModel | undefined) => void;
  cartIsLoading: boolean;
  invalidateCart: () => Promise<void>;
};

const CartContext = createContext<TCartContext>({
  cart: undefined,
  setCart: () => void 0,
  cartIsLoading: false,
  invalidateCart: noopPromise,
});

type TCartProviderProps = {
  children: ReactNode;
};

export const CartProvider = ({ children }: TCartProviderProps): JSX.Element => {
  const { profile } = useUserInfoContext();

  const {
    data: cart,
    mutate,
    error,
  } = useSWR<GetCartResponseModel, HttpClientError>(
    [profile ?? '', cartService.getCart.name],
    () => cartService.getCart(),
    {
      keepPreviousData: true,
    },
  );

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart: (value: GetCartResponseModel | undefined) =>
          void mutate(value),
        cartIsLoading: !cart && !error,
        invalidateCart: async () => void (await mutate()),
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = (): TCartContext => useContext(CartContext);
