'use client';
import { noop } from '@brand/utils';
import { useQueryParams } from 'hooks/use-query-params';
import { ReactElement, createContext, useCallback, useContext } from 'react';
import { TStackRouteProps } from './query-route.component';

export type TStackRouterProps = {
  paramName: string;
  children:
    | ReactElement<TStackRouteProps>
    | Array<ReactElement<TStackRouteProps>>;
};

type TStackRouterContext = {
  push: (route: string) => void;
  back: VoidFunction;
  current?: string | null;
};

const Context = createContext<TStackRouterContext>({
  push: noop,
  back: noop,
  current: null,
});

export const useQueryRouter = (): TStackRouterContext => useContext(Context);

export function QueryRouter({
  children,
  paramName,
}: TStackRouterProps): React.ReactNode {
  const params = useQueryParams();

  const push = (route: string): void => void params.set(paramName, route);
  const back = (): void => params.undo(paramName);

  return (
    <Context.Provider value={{ push, back, current: params.get(paramName) }}>
      {children}
    </Context.Provider>
  );
}
