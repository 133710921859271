import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcCheckIcon = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3939 6.16747L18.8636 6.6978L8.78028 16.7811C8.48739 17.074 8.01251 17.074 7.71962 16.7811L3.13629 12.1978L2.60596 11.6675L3.66662 10.6068L4.19695 11.1371L8.24995 15.1901L17.803 5.63714L18.3333 5.10681L19.3939 6.16747Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
