'use client';
import { noop } from '@brand/utils';
import cn from 'classnames';
import { type JSX } from 'react';
import Sheet from 'react-modal-sheet';
import { TModalProps } from './modal.component';
import styles from './modal.module.scss';

export const ModalMobile = ({
  onClose,
  detent = 'content-height',
  snapPoints,
  isOpen,
  children,
  contentClassName,
  className,
  disableDrag,
  ...props
}: TModalProps): JSX.Element | null => {
  return (
    <Sheet
      disableScrollLocking
      animate={false}
      layoutScroll
      isOpen={isOpen}
      onClose={onClose ?? noop}
      detent={detent}
      snapPoints={snapPoints}
      className={cn(className, { [styles.disableDrag]: disableDrag })}
      disableDrag={disableDrag}
      {...props}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <Sheet.Scroller
            style={{ overflowX: 'hidden' }}
            className="px-3 px-sm-4"
          >
            {children}
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
