import cn from 'classnames';
import { useState, type JSX } from 'react';
import InputMask from 'react-input-mask';
import styles from './input.module.scss';
import { Label } from '../label';
import { TLabelProps } from '../label/label.types';

export interface IInputProps
  extends Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'formAction' | 'children' | 'size'
    >,
    Omit<TLabelProps, 'children' | 'isFocused'> {
  /**
   * Маска
   */
  mask?: string | Array<string | RegExp>;
}

export const Input = ({
  label,
  tooltip,
  description,
  errorMessage,
  leftAccessory,
  rightAccessory,
  className,
  disabled,
  mask,
  fitHeight,
  size = 'm',
  ...inputProps
}: IInputProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const isActive = isFocused || Boolean(inputProps.value);
  const commonProps = {
    type: 'text',
    ...inputProps,
    disabled,
    className: cn(styles.input, { [styles.active]: isActive }),
    onFocus: (event: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(true);
      inputProps.onFocus?.(event);
    },
    onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(false);
      inputProps.onBlur?.(event);
    },
  };

  const children = (
    <div
      className={cn(styles.content, { [styles.active]: isActive && !!label })}
    >
      {leftAccessory}
      {mask ? (
        <InputMask {...commonProps} mask={mask} />
      ) : (
        <input {...commonProps} />
      )}
      {rightAccessory}
    </div>
  );

  const labelProps: TLabelProps = {
    size,
    label,
    tooltip,
    leftAccessory,
    rightAccessory,
    description,
    errorMessage,
    disabled,
    children,
    className,
    isFocused: isActive,
    fitHeight,
  };

  return <Label {...labelProps} />;
};
