/** eslint-disable @typescript-eslint/typedef */
/** eslint-disable react/jsx-no-undef */
/** eslint-disable prettier/prettier */
/** eslint-disable react/jsx-no-undef */
/** eslint-disable @typescript-eslint/typedef */
import React, { type JSX } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';

export type TSkeletonListProps = {
  /**
   * Скелетон который будет рендерится
   */
  renderItem: JSX.Element;
  /**
   * Колличество рядов
   */
  count: number;
};

export const SkeletonList = ({
  count,
  renderItem,
}: TSkeletonListProps): JSX.Element => {
  return (
    <>
      {Array(count)
        .fill(0)
        .map((_: unknown, index: number) => (
          <React.Fragment key={index}>{renderItem}</React.Fragment>
        ))}
    </>
  );
};
