import { Field, FieldProps } from 'formik';
import { type JSX } from 'react';
import { IAutocompleteProps, Autocomplete } from './autocomplete.component';

export function AutocompleteField<T>(
  props: IAutocompleteProps<T>,
): JSX.Element {
  return (
    <Field name={props.name} value={props.value}>
      {({ field, meta }: FieldProps<string>) => (
        <>
          <Autocomplete
            {...field}
            {...props}
            errorMessage={(meta.touched && meta.error) || undefined}
          />
        </>
      )}
    </Field>
  );
}
