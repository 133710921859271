import { Field, FieldProps } from 'formik';
import { type JSX } from 'react';
import { ICheckboxProps, Checkbox } from './checkbox.component';

export const CheckboxField = (props: ICheckboxProps): JSX.Element => (
  <Field name={props.name}>
    {({ field, meta }: FieldProps<string>) => (
      <Checkbox
        {...props}
        {...field}
        errorMessage={(meta.touched && meta.error) || undefined}
      />
    )}
  </Field>
);
