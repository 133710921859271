import {
  HttpClient,
  HttpClientResponse,
  NetworkError,
  isHttpClientError,
} from 'lib';
import { GetCartResponseModel } from 'modules/cart/api/models';
import { AddCartPositionResponseModel, ShippingType } from '../models';
import {
  DeliveryPointRequest,
  TDeliveryPointRequest,
} from '../models/delivery-points-request.model';
import { DeliveryPointSearch } from '../models/delivery-points-response.model';

/**
 * Транспорт корзины
 */
export class CartTransport {
  constructor(private httpClient: HttpClient) {}

  /**
   * Полученение позиций из карзины без данные о доставке
   */
  async getCart(): Promise<GetCartResponseModel> {
    try {
      const response = await this.httpClient.get<HttpClientResponse<object>>(
        '/cart/get',
      );
      return GetCartResponseModel.fromServer(response.data.data);
    } catch (error: unknown) {
      throw new NetworkError(`Не удалось запросить корзину`);
    }
  }

  /**
   * Добавление позиции в корзину
   */
  async addPosition(data: FormData): Promise<AddCartPositionResponseModel> {
    try {
      const response = await this.httpClient.post<HttpClientResponse<object>>(
        '/cart/add',
        data,
      );

      return AddCartPositionResponseModel.fromServer(response.data.data);
    } catch (error) {
      throw new NetworkError(`Не удалось запросить корзину`);
    }
  }

  /**
   * Удаление позиции из корзины
   */
  async removePosition(data: FormData): Promise<void> {
    try {
      const response = await this.httpClient.post<HttpClientResponse<void>>(
        '/cart/remove',
        data,
      );

      return response.data.data;
    } catch (error) {
      throw new NetworkError(`Не удалось запросить корзину`);
    }
  }

  /**
   * Обновление количества товаров в позиции
   */
  async updatePosition(data: FormData): Promise<GetCartResponseModel> {
    try {
      const response = await this.httpClient.post<HttpClientResponse<object>>(
        '/cart/update',
        data,
      );

      return GetCartResponseModel.fromServer(response.data.data);
    } catch (error) {
      throw new NetworkError(`Не удалось запросить корзину`);
    }
  }

  /**
   * Добавить купон
   */
  async addCoupon(data: FormData): Promise<GetCartResponseModel> {
    try {
      const response = await this.httpClient.post<HttpClientResponse<object>>(
        '/cart/add_coupon',
        data,
      );

      return GetCartResponseModel.fromServer(response.data.data);
    } catch (error: unknown) {
      if (isHttpClientError(error)) {
        throw new NetworkError(error.message);
      }
      throw new NetworkError('Не удалось добавить купон в корзину');
    }
  }

  /**
   * Удалить купон
   */
  async removeCoupon(data: FormData): Promise<GetCartResponseModel> {
    try {
      const response = await this.httpClient.post<HttpClientResponse<object>>(
        '/cart/remove_coupon',
        data,
      );

      return GetCartResponseModel.fromServer(response.data.data);
    } catch (error) {
      throw new NetworkError(`Не удалось удалить купон из корзины`);
    }
  }

  /**
   * Получение доступных способов доставки товара
   */
  async getShippingTypeList(): Promise<Array<ShippingType>> {
    try {
      const response = await this.httpClient.get<HttpClientResponse<object>>(
        '/cart/shipping_type_list',
      );
      const shippingTypesList = response.data.data;

      if (Array.isArray(shippingTypesList)) {
        return shippingTypesList.map((shippingType: object) =>
          ShippingType.fromServer(shippingType),
        );
      }

      return [];
    } catch (error) {
      throw new NetworkError(`Не удалось получить список доступных товаров`);
    }
  }

  /**
   * Получение точек самовывоза
   */
  async getPvzPoints(
    params: TDeliveryPointRequest,
  ): Promise<DeliveryPointSearch> {
    try {
      const response = await this.httpClient.get<
        HttpClientResponse<DeliveryPointSearch>
      >('/search/delivery/points', {
        params: new DeliveryPointRequest({
          ...params,
          fields: params?.fields ?? [
            'id',
            'address',
            'location',
            'type',
            'phone',
            'schedule',
            'trip',
          ],
        }).toServer(),
      });

      return DeliveryPointSearch.fromServer(response.data.data);
    } catch (error) {
      throw new NetworkError(`Не удалось получить список пунктов выдачи`);
    }
  }
}
