import variables from './breakpoints.module.scss';

type TBreakpoints = {
  s: null;
  sm: string;
  m: string;
  l: string;
  xl: string;
};

export type TBreakpoint = keyof TBreakpoints;
export const breakpoints = variables as unknown as TBreakpoints;
