import { debounce } from '@brand/utils';
import classNames from 'classnames';
import { Modal, TModalProps } from 'components/ui-kit-v2/modal/modal.component';
import { useFormikContext } from 'formik';
import { geoService } from 'lib';
import { SearchAddressModel } from 'lib/models/search-address.response';
import { TDeliveryPointRequest } from 'modules/cart/api/models/delivery-points-request.model';
import { DeliveryPoint } from 'modules/cart/api/models/delivery-points-response.model';
import { cartService } from 'modules/cart/services';
import { useCallback, useEffect, useRef, useState, type JSX } from 'react';
import useSWR from 'swr';
import ymaps from 'yandex-maps';
import { PvzList, TPvzListRef } from './pvz-list/pvz-list';
import { PvzMap } from './pvz-map/pvz-map';
import {
  DEFAULT_MAP_ZOOM,
  ON_POINT_CHOOSE_MAP_ZOOM,
} from './pvz-map/pvz-map.utils';
import styles from './pvz-modal.module.scss';
import { TOrderFormValues } from '../../../../order-form.const';

type TPvzModalProps = {
  onSelect: (delivery: DeliveryPoint) => void;
} & TModalProps;

export const PvzModal = ({
  onSelect,
  ...props
}: TPvzModalProps): JSX.Element => {
  const { values } = useFormikContext<TOrderFormValues>();
  const mapRef = useRef<ymaps.Map | null>(null);
  const listRef = useRef<TPvzListRef | null>(null);
  const [bounds, setBounds] = useState<TDeliveryPointRequest>({
    boundBottomLeftLat: 56.80673621615146,
    boundBottomLeftLon: 60.55091415885044,
    boundTopRightLat: 56.866156213458844,
    boundTopRightLon: 60.626359334021366,
    pageSize: 500,
  });
  const { data: points } = useSWR(
    bounds,
    (params: TDeliveryPointRequest) => cartService.getPvzPoints(params),
    { keepPreviousData: true },
  );

  const refreshData = (): void => {
    const mapBounds = mapRef.current?.getBounds();

    if (mapBounds) {
      setBounds({
        boundBottomLeftLat: mapBounds[0][0],
        boundBottomLeftLon: mapBounds[0][1],
        boundTopRightLat: mapBounds[1][0],
        boundTopRightLon: mapBounds[1][1],
        pageSize: 500,
      });
    }
  };

  const handlePointClick = (point: DeliveryPoint): void => {
    listRef.current?.selectPoint(point);
    void mapRef?.current
      ?.panTo([point.location.lat - 0.0005, point.location.lon])
      .then(() =>
        mapRef?.current?.setZoom(ON_POINT_CHOOSE_MAP_ZOOM, { duration: 500 }),
      );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((...params: Array<unknown>): void => {
      const search = params[0] as string;
      void geoService
        .searchAddress(search)
        .then((results: Array<SearchAddressModel>) => {
          const [result] = results;
          if (result?.lat && result?.lon) {
            void mapRef?.current
              ?.panTo([result.lat, result.lon])
              .then(refreshData)
              .then(() =>
                mapRef?.current?.setZoom(DEFAULT_MAP_ZOOM, { duration: 500 }),
              );
          }
        });
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 500),
    [mapRef],
  );

  const handleSelect = (point: DeliveryPoint): void => {
    onSelect(point);
    props.onClose?.();
  };

  useEffect(() => {
    if (values?.shippingAddress?.lat && values?.shippingAddress?.lon) {
      void mapRef?.current
        ?.panTo([values?.shippingAddress?.lat, values?.shippingAddress?.lon])
        .then(() =>
          mapRef?.current?.setZoom(DEFAULT_MAP_ZOOM, { duration: 500 }),
        );
    }
  }, [mapRef, values?.shippingAddress?.lat, values?.shippingAddress?.lon]);

  return (
    <>
      <Modal
        {...props}
        className={styles.modal}
        contentClassName={styles.container}
      >
        <div className={classNames(styles.listContainer)}>
          <PvzList
            onSelect={handleSelect}
            ref={listRef}
            points={points?.deliveryPoints ?? []}
            onPointClick={handlePointClick}
            onSearch={handleSearch}
          />
        </div>
      </Modal>

      <div
        className={classNames(styles.mapContainer, {
          [styles.isMapShow]: props.isOpen,
        })}
      >
        <PvzMap
          onPlacemarkClick={handlePointClick}
          onBoundsChange={refreshData}
          mapRef={mapRef}
          points={points?.deliveryPoints ?? []}
        />
      </div>
    </>
  );
};
