import { useStorage } from '@brand/utils';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

type TUseQueryParams = {
  set: (name: string, value: string) => void;
  get: (name: string) => string | null;
  undo: (name: string) => void;
};
const QUERY_PARAMS_HISTORY = 'QUERY_PARAMS_HISTORY';

export const useQueryParams = (): TUseQueryParams => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [getHistory, setHistory] = useStorage<Record<string, Array<string>>>(
    QUERY_PARAMS_HISTORY,
    'session',
  );

  const set = (name: string, value: string): void => {
    const current = new URLSearchParams(Array.from(searchParams.entries()));
    const history = getHistory() ?? {};

    if (!history[name]) {
      history[name] = [current.get(name) ?? ''];
    }

    if (!value) {
      current.delete(name);
      delete history[name];
    } else {
      current.set(name, value);
      history[name].push(value);
    }

    const search = current.toString();
    const query = search ? `?${search}` : '';
    setHistory(history);
    void router.push(`${pathname}${query}`);
  };

  const undo = (name: string): void => {
    const history = getHistory() ?? { [name]: [] };
    const targetHistory = history[name] ?? [];
    targetHistory.pop();
    const current = targetHistory.pop() ?? '';
    history[name] = targetHistory;

    setHistory(history);
    set(name, current);
  };

  return { undo, set, get: searchParams.get.bind(searchParams) };
};
