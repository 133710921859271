import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcPlus = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 3.75V4.5V11.25H19.5H20.25V12.75H19.5H12.75V19.5V20.25H11.25V19.5V12.75H4.5H3.75V11.25H4.5H11.25V4.5V3.75H12.75Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
