import cn from 'classnames';
import { toMediaValue } from 'lib';
import React, { memo, ReactNode, useMemo, type JSX } from 'react';
import { TBreakpoint, TColor } from 'styles/theme';
import styles from './typo.module.scss';

type TMediaValue<T> = Partial<Record<TBreakpoint, T>>;

type TVariant = '6xl' | '2xl' | 'xl' | 'l' | 's' | 'xs' | '2xs';

export type TTypoProps = {
  /**
   * Задает размеры шрифта из набора
   * По-умолчанию «m»
   */
  size?: TVariant | TMediaValue<TVariant>;

  /**
   * Стили начертания
   */
  decoration?: 'underline' | 'line-through';

  /**
   * Задает толщину начертания шрифта
   * По-умолчанию «400»
   */
  weight?: 'regular' | 'medium';
  /**
   * Задает горизонтальное выравнивание текста
   * По-умолчанию «left»
   */
  align?: 'left' | 'center' | 'right';
  /**
   * Дополнительный пользовательский класс
   */
  className?: string;
  /**
   * Цвет текста
   */
  color?: TColor | 'inherit';
  /**
   * Позволяет прокинуть «сырой» html в виде строки
   * Одновременно может использовать или children, или rawHtml
   */
  rawHtml?: string;
  /**
   *
   */
  lineClamp?: number;
  /**
   * Дочерние компоненты
   * Одновременно может использовать или children, или rawHtml
   */
  children?: ReactNode;
  /**
   * Тип элемента
   */
  type?: 'div' | 'span' | 'p' | 'h1' | 'h2';
  /**
   * Атрибут для тестов
   */
  dataAt?: string;
};

export const Typo = memo(function Typo({
  align = 'left',
  size = 's',
  weight = 'regular',
  color = 'Primitive-Primary',
  type = 'span',
  decoration,
  rawHtml,
  ...props
}: TTypoProps): JSX.Element {
  const classes = useMemo(
    () =>
      Object.entries(toMediaValue<TMediaValue<TVariant>>(size)).flatMap(
        ([media, value]: [string, TVariant]) => {
          return styles[`size-${value}-${media}`];
        },
      ),
    [size],
  );

  const defaultProps = {
    'className': cn(
      styles.typo,
      classes,
      styles[`decoration-${decoration ?? ''}`],
      {
        [styles[`align-${align}`]]: !!align,
        [styles[`weight-${weight}`]]: !!weight,
        [styles[`color-${color}`]]: !!color,
        [styles['line-clamp']]: !!props.lineClamp,
      },
      props.className,
    ),
    'style': {
      WebkitLineClamp: props.lineClamp,
    },
    'data-at': props.dataAt,
  };

  if (rawHtml)
    return React.createElement(type, {
      ...defaultProps,
      dangerouslySetInnerHTML: {
        __html: rawHtml,
      },
    });

  return React.createElement(type, defaultProps, props.children);
});
