import cn from 'classnames';
import { toMediaValue } from 'lib';
import React, { memo, useMemo, type JSX } from 'react';
import { spacing, TBreakpoint, TSpacing } from 'styles/theme';
import styles from './row.module.scss';

type TMediaValue<T> = Partial<Record<TBreakpoint, T>>;
type TAlignItems = 'flex-start' | 'center' | 'stretch' | 'flex-end';
type TJustifyContent =
  | 'flex-start'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'flex-end';

export type TRowProps = {
  /**
   *
   */
  children: React.ReactNode;
  /**
   * Пользовательский класс стилей
   */
  className?: string;
  /**
   * Разворачивает направление
   */
  reverse?: boolean;

  /**
   * Направление флекса
   */
  direction?: 'row' | 'column' | TMediaValue<'row' | 'column'>;
  /**
   * Отступы между колонок
   * Задается вертикальный и горизонтальный отступ
   * @default 2x
   */
  gap?:
    | TSpacing
    | [TSpacing, TSpacing]
    | TMediaValue<TSpacing | [TSpacing, TSpacing]>;
  /**
   * Вертикальное выравнивание
   */
  align?: TAlignItems | TMediaValue<TAlignItems>;
  /**
   * Горизонтальное выравнивание
   */
  justify?: TJustifyContent | TMediaValue<TJustifyContent>;
};

export const Row = memo(function Row({ ...props }: TRowProps): JSX.Element {
  const justify = toMediaValue<TMediaValue<TJustifyContent>>(props.justify);
  const align = toMediaValue<TMediaValue<TAlignItems>>(props.align);
  const gap = toMediaValue<TMediaValue<TSpacing | [TSpacing, TSpacing]>>(
    Array.isArray(props.gap) ? { s: props.gap } : props.gap,
  );
  const direction = toMediaValue<TMediaValue<'row' | 'column'>>(
    props.direction,
  );

  const classes = useMemo(
    () =>
      Object.entries({
        ['justify-content']: justify,
        ['align-items']: align,
        ['direction']: direction,
      }).flatMap(([cssProperty, values]: [string, object]) => {
        return Object.entries(values).map(
          ([media, value]: [string, string]) =>
            styles[`${cssProperty}-${value}-${media}`],
        );
      }),
    [justify, align, direction],
  );
  const gaps = useMemo(
    () => ({
      get s() {
        return gap?.s ?? '2x';
      },
      get sm() {
        return gap?.sm ?? this?.s;
      },
      get m() {
        return gap?.m ?? this.sm;
      },

      get l() {
        return gap?.l ?? this.m;
      },
      get xl() {
        return gap?.xl ?? this.l;
      },
    }),
    [gap],
  );

  const offsets = Object.entries(gaps).reduce(
    (
      acc: Record<string, string>,
      [media, value]: [string, TSpacing | [TSpacing, TSpacing]],
    ) => {
      const [x = '2x', y] = [value].flat();
      acc[`--offset-x-${media}`] = spacing[x];
      acc[`--offset-y-${media}`] = spacing[y] ?? spacing[x];
      return acc;
    },
    {},
  );

  return (
    <div
      style={offsets}
      className={cn(styles.row, classes, {
        [String(props.className)]: !!props.className,
        [styles.reverse]: props.reverse,
      })}
    >
      {props.children}
    </div>
  );
});
