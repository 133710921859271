import { deserialize, JsonName } from 'tserialize';

/** Модель цены */
export class PriceModel {
  /** Цена */
  @JsonName() price!: string;

  /** Цена(число) */
  @JsonName('price_value') priceValue!: number;

  /** Размер скидки */
  @JsonName('discount_price') discountPrice!: string;

  /** Размер скидки(число) */
  @JsonName('discount_price_value') discountPriceValue!: number;

  get totalPrice(): number {
    return this.priceValue + this.discountPriceValue;
  }

  static fromServer(data: unknown): PriceModel | null {
    if (!data) return null;
    return deserialize(data, this);
  }
}
