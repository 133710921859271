'use client';
import cn from 'classnames';
import { useState, type JSX } from 'react';
import styles from './clickable.module.scss';
import { Spinner } from '../spinner';

type TIconButtonProps = {
  /**
   * Колбек для событие клика
   * при вызове на время разрешения промиса
   * будет показан лоадер
   */
  onClick?: VoidFunction | (() => Promise<unknown>);

  /**
   * Кастомый компонент лоадера
   */
  loader?: JSX.Element;
  /**
   * Блокировка
   */
  disabled?: boolean;
  /**
   * VAI атрибут label
   */
  ariaLabel?: string;
  /**
   * Тестовый атрибут
   */
  dataAt?: string;
  /**
   * CSS-класс
   */
  className?: string;
};

export const Clickable = ({
  children,
  ariaLabel,
  onClick,
  dataAt,
  className,
  disabled,
  loader = <Spinner size={20} />,
}: React.PropsWithChildren<TIconButtonProps>): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = (): void => {
    if (isLoading || disabled) {
      return;
    }

    const result = onClick?.();

    if (typeof result !== 'undefined') {
      setIsLoading(true);
      void result.finally(() => void setIsLoading(false));
    }
  };

  return (
    <span
      role="button"
      data-at={dataAt}
      className={cn(
        styles.iconButton,
        { [styles.disabled]: disabled || isLoading },
        className,
      )}
      aria-label={ariaLabel}
      onClick={handleClick}
    >
      <span className={cn(styles.content, { [styles.isLoading]: isLoading })}>
        {children}
      </span>
      {isLoading && <span className={styles.loader}>{loader}</span>}
    </span>
  );
};
