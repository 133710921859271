'use client';
import { isBrowser, useScrollLock } from '@brand/utils';
import cn from 'classnames';
import { useCallback, useState, type JSX } from 'react';
import * as ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal';
import styles from './confirm.module.scss';
import { Box } from '../box';
import { Button } from '../button';
import { Clickable } from '../clickable';
import { Column, Row } from '../grid';
import { Icon } from '../icon-v2';
import { Typo } from '../typo';

type TConfirmProps = {
  /**
   * Контент
   */
  content?: JSX.Element;
  /**
   * Заголовок
   */
  header?: string;
  /**
   * Текст в кнопке согласия
   */
  acceptText?: string;
  /**
   * Текст в кнопке отменить
   */
  declineText?: string;
  /**
   * Колбек при нажатии согласия
   * Если передать колбек возрващаюший Promise, будет показ прелодаер в кнопке
   *
   */
  onAccept?: () => void | Promise<void>;
  /**
   * Колбек при закрытии далогового окна
   */
  onDecline?: () => void;
  /**
   * CSS-класс
   */
  className?: string;
  /**
   * CSS-класс
   */
  isOpen: boolean;
  /**
   * CSS-класс
   */
  onClose(): void;
};

const Confirm = ({
  onAccept,
  onDecline,
  content,
  header = 'Подтвердить?',
  acceptText = 'Да',
  declineText = 'Отмена',
  className,
  isOpen,
  onClose,
}: TConfirmProps): JSX.Element | null => {
  const [isLoading, setLoading] = useState(false);
  useScrollLock(isOpen);

  const handleAccept = async (): Promise<void> => {
    try {
      setLoading(true);
      await onAccept?.();
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleDecline = (): void => {
    onDecline?.();
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleDecline}
      overlayClassName={styles.overlay}
      className={cn(styles.container, className)}
    >
      <Box variant="outline" className={styles.content}>
        <Row direction="column">
          <Column span={12}>
            <Row justify="space-between" className="pb-4">
              <Column>
                <Typo size="2xl">{header}</Typo>
              </Column>
              <Column>
                <Clickable disabled={isLoading} onClick={handleDecline}>
                  <Icon icon="IcCross" />
                </Clickable>
              </Column>
            </Row>
          </Column>
          <Column span={12}>
            <Row className="py-3">
              <Column span={12}>{content}</Column>
            </Row>
          </Column>
          <Column span={12}>
            <Row className="pt-6">
              <Column span={6}>
                <Button
                  isBlock
                  isDisabled={isLoading}
                  variant="secondary"
                  onClick={handleDecline}
                >
                  {declineText}
                </Button>
              </Column>
              <Column span={6}>
                <Button
                  isBlock
                  isLoading={isLoading}
                  onClick={() => void handleAccept()}
                  variant="primary"
                >
                  {acceptText}
                </Button>
              </Column>
            </Row>
          </Column>
        </Row>
      </Box>
    </ReactModal>
  );
};

export const useConfirm = (
  props: Omit<TConfirmProps, 'isOpen' | 'onClose'>,
): { Confirm: JSX.Element | null; show: () => void; hide: () => void } => {
  const [isOpen, setIsOpen] = useState(false);
  const show = useCallback(() => setIsOpen(true), []);
  const hide = useCallback(() => setIsOpen(false), []);

  return {
    Confirm: <Confirm {...props} isOpen={isOpen} onClose={hide} />,
    show,
    hide,
  };
};
