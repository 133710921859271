'use client';
import { useRibbonContext } from 'components/ribbon';
import { Box, Column, Container, Row, Spacer } from 'components/ui-kit-v2';
import { Form, Formik, FormikHelpers } from 'formik';
import { carrotService, HttpClientError } from 'lib';
import { OrderCreateResponse, TOrderCreateRequest } from 'modules/cart/api';
import { orderService } from 'modules/cart/services';
import { useRouter } from 'next/navigation';
import { JSX, useEffect } from 'react';
import StickyBox from 'react-sticky-box';
import { FinalPriceAside } from './component';
import { AuthAside } from './component/auth-aside';
import { OrderForm } from './component/order-form';
import { DeliveryProvider } from './component/order-form/delivery-price-provider.component';
import {
  initialValues,
  TOrderFormValues,
  validationSchema,
} from './component/order-form/order-form.const';
import { SubHeader } from '../common/sub-header';

const HEADER_HEIGHT = 63;

export const checkoutTestSelector = {
  unauthorizeMessage: 'unauthorize-message',
};

export const Checkout = (): JSX.Element => {
  const { isVisibleRibbon, ribbonHeight } = useRibbonContext();
  const router = useRouter();

  const stickyOffset = isVisibleRibbon
    ? ribbonHeight + HEADER_HEIGHT
    : HEADER_HEIGHT;

  useEffect(() => {
    carrotService.orderStarted();
  }, []);

  const handleSubmit = async (
    values: TOrderCreateRequest,
    helpers: FormikHelpers<TOrderFormValues>,
  ): Promise<void> => {
    await orderService
      .create(values)
      .then((response: OrderCreateResponse | undefined | void) => {
        if (response) {
          void router.push(response.confirmation.confirmationUrl);
        }
      })
      .catch((error: HttpClientError) =>
        helpers.setFieldError('serverError', error.message),
      );
  };

  return (
    <DeliveryProvider>
      <Formik<TOrderFormValues>
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form placeholder="">
          <Box backgroundColor="Primitive-Primary-On-Inverse" variant="flat">
            <Container variant={{ s: 'fluid', m: 'default' }}>
              <div className="px-4 px-m-0">
                <SubHeader
                  title="Оформление заказа"
                  fallbackBackHistory="/cart/"
                />
              </div>
              <Row gap={{ s: ['0x', '2x'], m: '1x' }}>
                <Column span={{ s: 12, m: 3 }}>
                  <StickyBox offsetTop={stickyOffset} className="px-2 px-m-0">
                    <AuthAside />
                  </StickyBox>
                </Column>
                <Column span={{ s: 12, m: 6 }}>
                  <OrderForm />
                </Column>
                <Column span={{ s: 12, m: 3 }}>
                  <StickyBox offsetTop={stickyOffset} className="px-4 px-m-0">
                    <FinalPriceAside />
                    <Spacer size="10x" />
                  </StickyBox>
                </Column>
              </Row>
            </Container>

            <Spacer size="10x" />
          </Box>
        </Form>
      </Formik>
    </DeliveryProvider>
  );
};
