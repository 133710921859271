'use client';
import { type JSX } from 'react';
import { QUERY_PARAM_SCREEN } from './auth-modal.const';
import { ForgotPasswordRoute } from './route/forgot-password/forgot-password.route';
import { SuccessForgotPasswordRoute } from './route/forgot-password/succees-forgot-password.route';
import { LoginRoute } from './route/login/login.route';
import { SuccessLoginRoute } from './route/login/success-login.route';
import { LooksLikeEmployeeRoute } from './route/look-like-employee/look-like-employee.route';
import { RestorePasswordRoute } from './route/restore-password/restore-password.route';
import { SuccessRestorePasswordRoute } from './route/restore-password/success-restore-password.route';
import { ShouldChangePasswordRoute } from './route/should-change-password/should-change-psasword.route';
import { SignupRoute } from './route/signup/signup.route';
import { SuccessSignupRoute } from './route/signup/success-signup.view';
import { QueryRouter } from '../../components/query-router/query-router.component';

export const AuthModal = (): JSX.Element => {
  return (
    <QueryRouter paramName={QUERY_PARAM_SCREEN}>
      <LoginRoute />
      <SuccessLoginRoute />
      <ForgotPasswordRoute />
      <SuccessForgotPasswordRoute />
      <SignupRoute />
      <SuccessSignupRoute />
      <RestorePasswordRoute />
      <SuccessRestorePasswordRoute />
      <ShouldChangePasswordRoute />
      <LooksLikeEmployeeRoute />
    </QueryRouter>
  );
};

export default AuthModal;
