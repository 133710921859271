import { JsonStruct, JsonName, deserialize, serialize } from 'tserialize';
import { v4 as uuidv4 } from 'uuid';
import { CartOfferModel } from './cart-offer.model';
import { CartProductModel } from './cart-product.model';

// TODO: добавить JSDOC моделям после описания их в swagger
export class CartPositions {
  id: string = uuidv4();

  @JsonStruct(CartOfferModel) offer!: CartOfferModel;

  @JsonStruct(CartProductModel) product!: CartProductModel;

  @JsonName() price!: string;

  @JsonName() currency!: string;

  @JsonName() total!: string;

  @JsonName('total_value') totalValue!: number;

  @JsonName() quantity!: number;

  @JsonName('price_per_unit') pricePerUnit!: string;

  @JsonName('price_per_unit_value') pricePerUnitValue!: string;

  static fromServer(data: unknown): CartPositions {
    return deserialize(data, this);
  }

  toServer(): CartPositions {
    return serialize(this) as CartPositions;
  }
}
