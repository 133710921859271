import { deserialize, JsonName } from 'tserialize';

export enum DeliveryTypeCode {
  FREE = 'free',
  FREE_PVZ = 'free-pvz',
  PVZ_RUSSIA = 'pvz-russia',
  PVZ = 'pvz',
  COURIER = 'courier',
  COURIER_RUSSIA = 'courier-russia',
  FOR_STAFF = 'for-staff',
  PVZ_COMPANY = 'pvz_company',
  COURIER_COMPANY = 'courier_company',
}

/**
 *  Модель типа доставки
 */
export class ShippingType {
  /** Id типа */
  @JsonName() id!: string;

  /** Имя */
  @JsonName() name!: string;

  /** Код */
  @JsonName() code!: DeliveryTypeCode;

  /** Цена */
  @JsonName('price_value') priceValue!: string;

  /** Описание типа */
  @JsonName('preview_text') previewText!: string;

  /** Полная цена */
  @JsonName('price_full') priceFull!: string;

  static fromServer(data: unknown): ShippingType {
    return deserialize(data, this);
  }
}
