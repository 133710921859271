import { Box, Column, Link, Row, Skeleton, Typo } from 'components/ui-kit-v2';
import { MoneyFormatter } from 'lib';
import { useCartContext } from 'modules/cart/providers';
import { type JSX } from 'react';
import { spacing } from 'styles/theme';
import { CartPositions } from '../cart-positions';
import { CreateOrder } from '../order-form/components/create-order.component';
import { useDeliveryTypeContext } from '../order-form/delivery-price-provider.component';

const FREE_DELIVERY_GOAL_PRICE = 5000;
export const FinalPriceAside = (): JSX.Element => {
  const { cart, cartIsLoading } = useCartContext();
  const { delivery } = useDeliveryTypeContext();

  const price = cart?.positionTotalPrice?.priceValue ?? NaN;
  const discountPrice = cart?.totalPrice?.discountPriceValue;
  const hasDiscount = discountPrice && discountPrice > 0;
  const isEnoughForFreeDelivery = price >= FREE_DELIVERY_GOAL_PRICE;
  const totalPrice = cart?.totalPrice?.totalPrice ?? NaN;

  return (
    <Box className="px-4 py-4">
      <Typo size="2xl">Стоимость заказа</Typo>
      <Row
        justify={{ s: hasDiscount ? 'space-between' : 'flex-end' }}
        className="pt-4"
      >
        {!!hasDiscount && (
          <Column>
            <Typo
              color="Primitive-Secondary"
              size="l"
              decoration="line-through"
              type="div"
            >
              {MoneyFormatter.format(totalPrice)}
            </Typo>
          </Column>
        )}
        <Column>
          <Skeleton
            isLoading={cartIsLoading}
            width="150px"
            height={spacing['12x']}
          >
            <Typo size="6xl" type="div" align="right">
              {MoneyFormatter.format(price || 0)}
            </Typo>
          </Skeleton>
        </Column>
      </Row>
      <div className="pt-16">
        <CartPositions />

        <Row justify={{ s: 'space-between' }} className="pt-4">
          <Column>
            <Typo size="l" weight="medium">
              Доставка
            </Typo>
          </Column>
          <Column>
            <Skeleton
              isLoading={cartIsLoading}
              width="100px"
              height={spacing['4x']}
            >
              <Typo size="l" weight="medium">
                {MoneyFormatter.format(
                  isEnoughForFreeDelivery ? 0 : +(delivery?.priceFull ?? 0),
                )}
              </Typo>
            </Skeleton>
          </Column>
          <Column span={{ s: 12 }}>
            <Typo color="Primitive-Secondary">
              Заказ поедет из&nbsp;Екатеринбурга и&nbsp;будет у&nbsp;вас
              в&nbsp;привычные сроки транспортных компаний.{' '}
              <Link href="/delivery">Подробнее</Link>
            </Typo>
          </Column>

          <Column span={{ s: 12, m: 0 }}>
            <CreateOrder />
          </Column>
        </Row>
      </div>
    </Box>
  );
};
