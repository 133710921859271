import { Field, FieldProps } from 'formik';
import { ChangeEvent, type JSX } from 'react';
import { IInputProps, Input } from './input.component';

export const InputField = (props: IInputProps): JSX.Element => (
  <Field name={props.name}>
    {({ field, meta }: FieldProps<string>) => (
      <Input
        {...props}
        {...field}
        errorMessage={(meta.touched && meta.error) || undefined}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          props.onChange?.(event);
          field.onChange?.(event);
        }}
      />
    )}
  </Field>
);
