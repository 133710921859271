import cn from 'classnames';
import {
  CSSProperties,
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
  type JSX,
} from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import styles from './scrollable.module.scss';

type TScrollableProps = {
  maxHeight?: number | string;
  className?: string;
  hideHorizontal?: boolean;
  hideVertical?: boolean;
} & PropsWithChildren &
  React.HTMLAttributes<HTMLElement>;

export const Scrollable = forwardRef(
  (
    {
      maxHeight,
      children,
      className,
      hideHorizontal,
      hideVertical,
      style = {},
      ...props
    }: TScrollableProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    return (
      <SimpleBar
        {...props}
        className={cn(styles.scrollbar, className, {
          [styles.hideHorizontal]: hideHorizontal,
          [styles.hideVertical]: hideVertical,
        })}
        style={
          {
            '--height':
              typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight,
            ...style,
          } as CSSProperties
        }
        scrollableNodeProps={{
          ref,
          style: { overscrollBehavior: 'contain' },
        }}
      >
        {children}
      </SimpleBar>
    );
  },
);
