import { useQueryRouter } from 'components/query-router';
import { Button, Typo } from 'components/ui-kit-v2';
import {
  Modal,
  ModalContent,
  ModalHeader,
} from 'components/ui-kit-v2/modal/modal.component';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { type JSX } from 'react';
import styles from '../../auth-modal.module.scss';
export const SuccessForgotPasswordRoute = (): JSX.Element => {
  const router = useQueryRouter();

  return (
    <Modal
      onClose={() => router.push('')}
      isOpen={Boolean(router.current === EAuthScreen.SUCCESS_FORGOT_PASSWORD)}
      contentClassName={styles.modal}
    >
      <ModalHeader>
        <Typo size="xl">
          Мы&nbsp;отправили вам инструкцию для восстановления пароля
          на&nbsp;указанный Email
        </Typo>
      </ModalHeader>
      <ModalContent>
        <Button isBlock onClick={() => router.push('')} className="mt-6">
          Окей
        </Button>
      </ModalContent>
    </Modal>
  );
};
