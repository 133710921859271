import { useSearchParams } from 'next/navigation';
import { type JSX } from 'react';

export type TFeaturesFlag = 'company_payment';
export type TFeatureFlagProps = {
  flag: TFeaturesFlag | Array<TFeaturesFlag>;
  children: JSX.Element;
};
export const FeatureFlag = ({
  flag,
  children,
}: TFeatureFlagProps): JSX.Element | null => {
  const query = useSearchParams();
  if (query.get('experimental')?.includes([flag].flat().toString())) {
    return children;
  }

  return null;
};
