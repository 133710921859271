import { useQueryRouter } from 'components/query-router';
import { Typo } from 'components/ui-kit-v2';
import {
  Modal,
  ModalContent,
  ModalHeader,
} from 'components/ui-kit-v2/modal/modal.component';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { type JSX } from 'react';
import styles from '../../auth-modal.module.scss';
import { RestorePasswordForm } from '../../components/restore-password-form.component';

export const RestorePasswordRoute = (): JSX.Element => {
  const router = useQueryRouter();

  return (
    <Modal
      onClose={() => router.push(EAuthScreen.SHOULD_CHANGE_PASSWORD)}
      isOpen={Boolean(router.current === EAuthScreen.RESTORE_PASSWORD)}
      contentClassName={styles.modal}
    >
      <ModalHeader>
        <Typo size="xl">Придумайте новый пароль</Typo>
      </ModalHeader>
      <ModalContent>
        <RestorePasswordForm
          onSuccess={() => router.push(EAuthScreen.SUCCESS_RESTORE_PASSWORD)}
        />
      </ModalContent>
    </Modal>
  );
};
