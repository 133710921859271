import { Clickable, Icon } from 'components/ui-kit-v2';
import {
  DeliveryPoint,
  DeliveryType,
} from 'modules/cart/api/models/delivery-points-response.model';
import { Ref, useCallback, useEffect, useRef, type JSX } from 'react';
import {
  Clusterer,
  Placemark,
  Map as YMap,
  YMaps,
  withYMaps,
} from 'react-yandex-maps';
import type ymaps from 'yandex-maps';
import styles from './pvz-map.module.scss';
import { TPvzMapProps, BoundsChangeEvent } from './pvz-map.types';
import { defaultState, getDeliveryIcons, mergeRefs } from './pvz-map.utils';
const BOUNDS_OFFSET = 0.15;

const modules = ['templateLayoutFactory', 'geolocation'];
const Map = withYMaps(
  ({
    ymaps,
    mapRef,
    points,
    onBoundsChange,
    onPlacemarkClick,
  }: TPvzMapProps) => {
    const ref = useRef<ymaps.Map | null>(null);
    const deliveryPoints = points.reduce<
      Record<DeliveryType, Array<DeliveryPoint>>
    >(
      (acc: Record<string, Array<DeliveryPoint>>, point: DeliveryPoint) => {
        acc[point.type].push(point);
        return acc;
      },
      { boxberry: [], cdek: [], pony: [] },
    );
    const moveToCurrentLocation = useCallback(() => {
      void ymaps.geolocation
        .get({
          provider: 'browser',
          mapStateAutoApply: true,
        })
        .then(function (result: ymaps.geolocation.IGeolocationResult) {
          const object = result.geoObjects.get(0);
          void ref.current?.panTo(object.geometry?.getBounds() ?? []);
        });
    }, [ref, ymaps]);

    useEffect(() => {
      moveToCurrentLocation();
    }, [moveToCurrentLocation]);

    const handleBoundsChange = ({
      originalEvent: { oldCenter, newCenter, oldZoom, newZoom, oldBounds },
    }: BoundsChangeEvent): void => {
      const isZoomChanged = oldZoom !== newZoom;
      const boundsWidth = oldBounds[1][1] - oldBounds[0][1];
      const boundsHeight = oldBounds[1][0] - oldBounds[0][0];
      const isBoundsChanged =
        Math.abs(oldCenter[0] - newCenter[0]) / boundsHeight > BOUNDS_OFFSET ||
        Math.abs(oldCenter[1] - newCenter[1]) / boundsWidth > BOUNDS_OFFSET;

      if (isZoomChanged || isBoundsChanged) {
        onBoundsChange();
      }
    };

    const zoom = (value: number): void => {
      const currentZoom = (ref?.current?.getZoom() ?? 0) + value;
      if (currentZoom > 4 && currentZoom < 21) {
        void ref?.current?.setZoom(currentZoom, { duration: 500 });
      }
    };

    return (
      <>
        <YMap
          className={styles.map}
          defaultState={defaultState}
          height="100%"
          width="100%"
          onBoundsChange={handleBoundsChange}
          instanceRef={
            mergeRefs(mapRef, ref) as (instance: Ref<unknown>) => void
          }
        >
          {Object.entries(deliveryPoints).map(
            ([type, deliveries]: [string, Array<DeliveryPoint>]) => {
              const { clustererConfigure, placeMarkOptions } = getDeliveryIcons(
                type as DeliveryType,
                ymaps,
              );
              return (
                <Clusterer
                  key={type}
                  options={{ gridSize: 512 }}
                  instanceRef={
                    clustererConfigure as (instance: Ref<unknown>) => void
                  }
                >
                  {deliveries.map((point: DeliveryPoint) => (
                    <Placemark
                      key={point.id}
                      geometry={[point.location.lat, point.location.lon]}
                      onClick={() => onPlacemarkClick(point)}
                      options={placeMarkOptions}
                    />
                  ))}
                </Clusterer>
              );
            },
          )}
        </YMap>
        <div className={styles.zoomControl}>
          <Clickable onClick={() => zoom(1)}>
            <Icon icon="IcPlus" />
          </Clickable>
          <Clickable onClick={() => zoom(-1)}>
            <Icon icon="IcMinus" />
          </Clickable>
        </div>

        <Clickable
          className={styles.geoControl}
          onClick={moveToCurrentLocation}
        >
          <Icon icon="IcGeo" />
        </Clickable>
      </>
    );
  },
  true,
  modules,
);

export const PvzMap = (props: Omit<TPvzMapProps, 'ymaps'>): JSX.Element => {
  return (
    <YMaps query={{ apikey: process.env.yandexApiKey }}>
      <Map {...(props as TPvzMapProps)} />
    </YMaps>
  );
};
