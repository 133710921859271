import { JsonName } from 'tserialize';

/** Модель свойств продукта */
export class ProductPropertyModel {
  /** Код продукта */
  @JsonName() code!: string;

  /** Имя */
  @JsonName() name!: string;

  /** Значение */
  @JsonName() value!: string;

  /** Описание */
  @JsonName() description!: string;
}
