import cn from 'classnames';
import { memo } from 'react';
import styles from './spinner.module.scss';

export enum SpinnerSpeed {
  FAST = 600,
  SLOW = 2000,
}

type TSpinnerProps = {
  /**
   * CSS-класс
   */
  className?: string;
  /**
   * Цвет
   */
  color?: string;
  /**
   * Тонкость начерастия
   */
  thickness?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  /**
   * Разрыв в окружности
   */
  gap?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /**
   * Скорость анимации
   */
  speed?: SpinnerSpeed;
  /**
   * Размер
   */
  size?: number | string;
};

export const Spinner = memo(function Spinner({
  color = styles.defaultColor,
  speed = SpinnerSpeed.SLOW,
  gap = 10,
  thickness = 2,
  size = '18px',
  className,
  ...props
}: TSpinnerProps) {
  return (
    <svg
      height={size}
      width={size}
      {...props}
      style={{ animationDuration: `${speed}ms` }}
      className={cn(styles.spinner, className)}
      viewBox="0 0 32 32"
    >
      <circle
        cx={16}
        cy={16}
        r={14 - thickness / 2}
        stroke={color}
        fill="none"
        strokeWidth={thickness}
        strokeDasharray={Math.PI * 2 * (11 - gap)}
        strokeLinecap="round"
      />
    </svg>
  );
});
