export enum EOAuthType {
  TOCHKA_EMPLOYEE = 'tochka-employee',
  TOCHKA_ID = 'tochka-id',
}

export const TOCHKA_EMPLOYEE_LINK =
  `${process.env.oauthTochkaEmployeeIdURI}/authorize` +
  `?client_id=${process.env.oauthTochkaEmployeeClientId}` +
  `&redirect_uri=${process.env.oauthTochkaEmployeeAuthRedirect}` +
  '&response_type=code&scope=default';

export const TOCHKA_ID_LINK =
  `${process.env.oauthTochkaIdURI}/api/v1/auth/sso/authorize` +
  `?client_id=${process.env.oauthTochkaIdClientId}` +
  `&redirect_uri=${process.env.oauthTochkaIdAuthRedirect}` +
  '&response_type=code&scope=default';
