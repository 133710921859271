import { JsonName, serialize } from 'tserialize';

export type TOrderPaymentInfoRequest = Omit<
  OrderPaymentInfoRequest,
  'toServer'
>;

export class OrderPaymentInfoRequest {
  /** Номер заказа
   */
  @JsonName('order_id') orderId!: number;

  constructor(data: TOrderPaymentInfoRequest) {
    Object.assign(this, data);
  }

  toServer(): TOrderPaymentInfoRequest {
    return serialize(this) as TOrderPaymentInfoRequest;
  }
}
