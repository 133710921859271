'use client';
import { noop } from '@brand/utils';
import { Breakpoint } from '_shared';
import { useMedia, useMediaQuery } from 'hooks';
import { PropsWithChildren, createContext, useContext, type JSX } from 'react';
import { SheetDetent } from 'react-modal-sheet/dist/types';
import { breakpoints } from 'styles/theme';
import { ModalDesktop } from './modal-desktop.component';
import { ModalMobile } from './modal-mobile.component';
import styles from './modal.module.scss';
import { Clickable } from '../clickable';
import { Icon } from '../icon-v2';

export type TModalProps = {
  /**
   * Контент
   */
  children?: React.ReactNode;
  /**
   * Колбек при закрытии далогового окна
   */
  onClose?: () => void;
  /**
   * CSS-класс
   */
  className?: string;
  contentClassName?: string;
  isOpen: boolean;
  snapPoints?: Array<number>;
  detent?: SheetDetent;
  /**
   * Отключить поддержку жестов в мобиле
   */
  disableDrag?: boolean;
};

type TModalContext = {
  close?: () => void;
};

const Context = createContext<TModalContext>({ close: noop });
export const useModal = (): TModalContext => useContext(Context);

export const Modal = (props: TModalProps): JSX.Element | null => {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.m})`);

  return (
    <Context.Provider value={{ close: props.onClose }}>
      {isMobile ? <ModalMobile {...props} /> : <ModalDesktop {...props} />}
    </Context.Provider>
  );
};

export const ModalHeader = ({ children }: PropsWithChildren): JSX.Element => {
  const { close } = useContext(Context);
  const breakpoint = useMedia();
  const isMobile = breakpoint < Breakpoint.MEDIUM_SCREEN;

  if (isMobile) {
    return <>{children}</>;
  }
  return (
    <div className={styles.modalHeader}>
      <div>{children}</div>
      <Clickable onClick={close} className="mt-1">
        <Icon icon="IcCross" />
      </Clickable>
    </div>
  );
};

export const ModalContent = ({ children }: PropsWithChildren): JSX.Element => {
  return <div className="py-3">{children}</div>;
};
