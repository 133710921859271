import cn from 'classnames';
import React, {
  ButtonHTMLAttributes,
  HTMLAttributes,
  memo,
  MouseEvent,
} from 'react';
import styles from './button.module.scss';
import { Spinner } from '../spinner';
import { Typo } from '../typo';

type TButtonTypes = 'button' | 'span';
type TButtonVariants = 'primary' | 'secondary' | 'ghost' | 'brand';
type TButtonSize = 's' | 'm';
export type TButtonProps = {
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  /**
   * Какой элемент использовать
   */
  as?: TButtonTypes;
  /**
   * Задает доступность кнопки.
   * По-умолчанию равен «false»
   */
  isDisabled?: boolean;
  /**
   * Задает вариант внешнего вида кнопки
   */
  variant?: TButtonVariants;
  /**
   * Задает размер кнопки
   * @default m
   */
  size?: TButtonSize;
  /**
   * Задает ширину кнопки относительно контейнера
   */
  isBlock?: boolean;
  /**
   * Дочерние компоненты
   */
  children: React.ReactNode;

  /**
   * Акссесуар справа
   */
  accRight?: React.ReactNode;
  /**
   * Тестовый атрибут
   */
  dataAt?: string;
  /**
   * CSS-класс
   */
  className?: string;
  /**
   * Показывает индикатор загрузки
   */
  isLoading?: boolean;
} & HTMLAttributes<HTMLElement>;

export const Button = memo(function Button({
  className,
  isDisabled,
  isBlock,
  accRight,
  isLoading,
  as = 'button',
  children,
  variant = 'primary',
  size,
  ...props
}: TButtonProps) {
  return React.createElement(
    as,
    {
      ...props,
      disabled: isDisabled,
      onClick: (event: MouseEvent<HTMLButtonElement>) =>
        isLoading || isDisabled ? undefined : props.onClick?.(event),
      className: cn(
        styles.button,
        styles[`variant-${variant}`],
        styles[`size-${size}`],
        {
          [styles.isLoading]: isLoading,
          [styles[`with-accesory`]]: !!accRight,
          [styles[`variant-${variant}-disabled`]]: isDisabled,
          [styles.buttonBlock]: isBlock,
        },
        className,
      ),
    },
    <div className={styles.inner}>
      <Typo size={{ s: 's', m: 'l' }} className={styles.content}>
        {children}
      </Typo>
      {!!accRight && <span className={styles.accesorie}>{accRight}</span>}
      {isLoading && (
        <div className={styles.loader}>
          <Spinner size={24} />
        </div>
      )}
    </div>,
  );
});
