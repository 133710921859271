import { Field, FieldProps } from 'formik';
import { type JSX } from 'react';
import { IInputPasswordProps, InputPassword } from './input-password.component';

export const InputPasswordField = (props: IInputPasswordProps): JSX.Element => (
  <Field name={props.name}>
    {({ field, meta }: FieldProps<string>) => (
      <InputPassword
        {...props}
        {...field}
        errorMessage={(meta.touched && meta.error) || undefined}
      />
    )}
  </Field>
);
