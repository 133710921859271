import { deserialize, JsonArray, JsonStruct, serialize } from 'tserialize';
import { CartPositions } from './cart-position.model';
import { CouponModel } from './coupon.model';
import { PriceModel } from './price.model';

/** Модель запроса корзины */
export class GetCartResponseModel {
  /** Позиции корзины(товары) */
  @JsonArray(CartPositions) positions: Array<CartPositions> = [];

  /** Финальная цена(доставка + товары) */
  @JsonStruct(PriceModel, 'total_price') totalPrice?: PriceModel;

  /** Цена товаров */
  @JsonStruct(PriceModel, 'position_total_price')
  positionTotalPrice?: PriceModel;

  /** Цена доставки */
  @JsonStruct(PriceModel, 'shipping_price') shippingPrice?: PriceModel | null;

  /** Купон */
  @JsonStruct(CouponModel) coupon?: CouponModel;

  get priceForDelivery(): number {
    return this.totalPrice?.priceValue ?? 0;
  }

  toServer(): GetCartResponseModel {
    return serialize(this) as GetCartResponseModel;
  }

  static fromServer(data: unknown): GetCartResponseModel {
    return deserialize(data, this);
  }
}
