import { HttpClient } from 'lib/http-client';
import { Office } from 'lib/models/office-response.model';
import { OfficeTransport } from 'lib/transports/office.transport';

/**
 * Сервис гео данных
 */
class OfficeService {
  private transport: OfficeTransport;

  constructor(httpClient: HttpClient) {
    this.transport = new OfficeTransport(httpClient);
  }

  /**
   * Получить список офисов
   */
  search(search: string = ''): Promise<Array<Office>> {
    return this.transport.search(search);
  }
}

export const officeService = new OfficeService(new HttpClient());
