import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcSquareNull = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75004 2.75H3.50016H20.5001H21.25L21.2501 3.49992C21.2505 7.3312 21.2504 11.1625 21.2503 14.9938C21.2502 16.8292 21.2501 18.6646 21.2501 20.5V21.25H20.5001H3.50014H2.75L2.75014 20.4999C2.75121 14.8333 2.75102 9.16672 2.75016 3.50011L2.75004 2.75ZM4.25027 4.25C4.251 9.41664 4.25115 14.5833 4.25028 19.75H19.7501C19.7502 18.1645 19.7502 16.5791 19.7503 14.9936C19.7504 11.4124 19.7505 7.83121 19.7502 4.25H4.25027Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
