import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcEyeOpen = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.97441 9.99999C4.55701 12.3343 7.12261 13.8333 9.99995 13.8333C12.8773 13.8333 15.4429 12.3342 17.0255 9.99999C16.2505 8.85698 15.2399 7.91423 14.0724 7.24969C14.3466 7.83125 14.4999 8.48105 14.4999 9.16666C14.4999 11.6519 12.4852 13.6667 9.99995 13.6667C7.51467 13.6667 5.49995 11.6519 5.49995 9.16666C5.49995 8.48105 5.65327 7.83125 5.92751 7.24968C4.75999 7.91424 3.74935 8.85698 2.97441 9.99999ZM9.99995 4.66666C6.39266 4.66666 3.2417 6.65068 1.44241 9.61039L1.20557 9.99999L1.44241 10.3896C3.2417 13.3493 6.39266 15.3333 9.99995 15.3333C13.6072 15.3333 16.7582 13.3493 18.5575 10.3896L18.7943 9.99999L18.5575 9.61039C16.7582 6.65068 13.6072 4.66666 9.99995 4.66666ZM9.99995 6.16666C8.34309 6.16666 6.99995 7.5098 6.99995 9.16666C6.99995 10.8235 8.34309 12.1667 9.99995 12.1667C11.6568 12.1667 12.9999 10.8235 12.9999 9.16666C12.9999 7.5098 11.6568 6.16666 9.99995 6.16666Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
