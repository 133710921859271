import cn from 'classnames';
import { Typo } from 'components/ui-kit-v2';
import { useState, type JSX } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './textarea.module.scss';
import { Label } from '../label';
import { TLabelProps } from '../label/label.types';

export interface ITextareaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'style'>,
    Omit<TLabelProps, 'children' | 'isFocused'> {
  value?: string;
  maxRows?: number;
  minRows?: number;
  cacheMeasurements?: boolean;
}

const MAX_LENGTH = 255;
export const Textarea = ({
  label,
  tooltip,
  description,
  errorMessage,
  leftAccessory,
  rightAccessory,
  className,
  disabled,
  size = 'm',
  fitHeight,
  maxLength = MAX_LENGTH,
  ...inputProps
}: ITextareaProps): JSX.Element => {
  const [count, setCount] = useState<number>(
    () => inputProps.value?.length ?? 0,
  );
  const [isFocused, setIsFocused] = useState(false);
  const isActive = isFocused || Boolean(inputProps.value);
  const commonProps = {
    type: 'text',
    ...inputProps,
    disabled,
    maxLength,
    className: cn(styles.input, { [styles.active]: isActive }),
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCount(event.currentTarget.value.length ?? '');
      inputProps.onChange?.(event);
    },
    onFocus: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => {
      setIsFocused(true);
      inputProps.onFocus?.(event);
    },
    onBlur: (event: React.FocusEvent<HTMLTextAreaElement, Element>) => {
      setIsFocused(false);
      inputProps.onBlur?.(event);
    },
  };

  const children = (
    <>
      <div className={cn(styles.content, { [styles.active]: isActive })}>
        {leftAccessory}
        <TextareaAutosize
          {...commonProps}
          className={cn(styles.textarea, className)}
        />
      </div>
      <Typo size="xs" color="Primitive-Secondary" className={styles.counter}>
        {count}/{maxLength}
      </Typo>
    </>
  );

  const labelProps: TLabelProps = {
    size,
    label,
    tooltip,
    leftAccessory,
    rightAccessory,
    description,
    errorMessage,
    disabled,
    children,
    className,
    fitHeight,
    isFocused: isActive,
  };

  return <Label {...labelProps} />;
};
