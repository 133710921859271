import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcShevronUp = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 8.5986L12.416 8.87595L19.916 13.876L20.5401 14.292L19.708 15.5401L19.084 15.124L12 10.4014L4.91605 15.124L4.29201 15.5401L3.45996 14.292L4.084 13.876L11.584 8.87595L12 8.5986Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
