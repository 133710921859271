import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const Warning = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16699 2.16675H2.91699H17.0837H17.8337V2.91675V17.0834V17.8334H17.0837H2.91699H2.16699V17.0834V2.91675V2.16675ZM3.66699 3.66675V16.3334H16.3337V3.66675H3.66699ZM10.7586 5.69866L10.7574 6.44866L10.7503 10.8346L10.7491 11.5846L9.24912 11.5822L9.25033 10.8322L9.25741 6.44624L9.25862 5.69624L10.7586 5.69866ZM9.25748 12.8027H10.0075H10.0145H10.7645V14.3027H10.0145H10.0075H9.25748V12.8027Z"
      fill={color ? colors[color] : 'currentColor'}
    />
  </svg>
);
