import {
  Button,
  Clickable,
  Column,
  Icon,
  Row,
  Typo,
} from 'components/ui-kit-v2';
import { DeliveryPoint } from 'modules/cart/api/models/delivery-points-response.model';
import { type JSX } from 'react';
import styles from './pvz-list.module.scss';

type TPvzListItemProps = {
  onSelect: (delivery: DeliveryPoint) => void;
  onBack: () => void;
  point: DeliveryPoint;
};

export const PvzListItem = ({
  onBack,
  onSelect,
  point,
}: TPvzListItemProps): JSX.Element => {
  // const [deliveryTime, setDeliveryTime] = useState('');

  // useEffect(() => {
  //   void delay(1000).then(() =>
  //     setDeliveryTime(`Через ${Math.floor(Math.random() * 10)} дня`),
  //   );
  // }, [point]);

  return (
    <div className={styles.container}>
      <div>
        <Row className={styles.head}>
          <Column>
            <Clickable onClick={onBack}>
              <Icon icon="IcArrowLeftIcon" />
            </Clickable>
          </Column>
          <Column>
            <Typo size="xl" className="mx-3">
              Пункта выдачи {point?.name}
            </Typo>
          </Column>
        </Row>
        <Row
          className="mt-5 mt-m-10"
          gap={{ s: ['0x', '2x'], m: ['0x', '8x'] }}
        >
          <Column span={12}>
            <Typo type="div" size="s" color="Primitive-Secondary">
              Адрес
            </Typo>
            <Typo type="div" size="s">
              {point?.address}
            </Typo>
          </Column>
          <Column span={12}>
            <Typo type="div" size="s" color="Primitive-Secondary">
              Время работы
            </Typo>
            <Typo type="div" size="s">
              {point?.schedule}
            </Typo>
          </Column>
          <Column span={12}>
            <Typo type="div" size="s" color="Primitive-Secondary">
              Период доставки
            </Typo>
            <Typo type="div" size="s">
              Заказ поедет из&nbsp;Екатеринбурга, после оформления пришлем
              трек-номер на&nbsp;ваш Email.
            </Typo>
          </Column>
        </Row>
      </div>
      <Button className="mt-3" onClick={() => onSelect(point)}>
        Забрать здесь
      </Button>
    </div>
  );
};
