import cn from 'classnames';
import { toMediaValue } from 'lib/utils';
import React, { memo, useMemo, type JSX } from 'react';
import { TBreakpoint } from 'styles/theme';
import styles from './column.module.scss';

export const checkOffsetExists = (offset?: number): boolean => {
  return typeof offset === 'number';
};

type TColumnWidth = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type TAlign = 'flex-start' | 'center' | 'flex-end';
type TMediaValue<T> = Partial<Record<TBreakpoint, T>>;

type TSpan = {
  s: TColumnWidth | 'auto';
  m?: TColumnWidth | 'auto';
  l?: TColumnWidth | 'auto';
  xl?: TColumnWidth | 'auto';
};

type TOffset = {
  s?: TColumnWidth;
  m?: TColumnWidth;
  l?: TColumnWidth;
  xl?: TColumnWidth;
};

export type TColumnProps = {
  /**
   * Группа свойств, в которой указывается ширина колонки
   * в определенной контрольной точке
   */
  span?: (TColumnWidth | 'auto') | TSpan;
  /**
   * Группа свойств, в которой указывается отступ для колонки
   * в определенной контрольной точке
   */
  offset?: TOffset;
  /**
   *
   */
  children: React.ReactNode;
  /**
   * Пользовательский класс стилей
   */
  className?: string;
  /**
   * Вертикальное выравнивание
   */
  align?: TAlign | TMediaValue<TAlign>;
  /**
   * Горизонтальное выравнивание
   */
  justify?: TAlign | TMediaValue<TAlign>;
  /**
   * Порядок элемента
   */
  order?: number | TMediaValue<number>;
};

export const Column = memo(function Column(props: TColumnProps): JSX.Element {
  const span = toMediaValue<TSpan>(props.span, 'auto');
  const justify = toMediaValue<TMediaValue<TAlign>>(props.justify);
  const align = toMediaValue<TMediaValue<TAlign>>(props.align);
  const order = toMediaValue<TMediaValue<number>>(props.order);

  const classes = useMemo(
    () =>
      Object.entries({
        ['align-self']: align,
        ['justify-self']: justify,
        order,
      }).flatMap(([cssProperty, values]: [string, object]) => {
        return Object.entries(values).map(
          ([media, value]: [string, string]) =>
            styles[`${cssProperty}-${value}-${media}`],
        );
      }),
    [align, justify, order],
  );

  return (
    <div
      className={cn(
        classes,
        styles[`span-${span.s}`],
        styles[`span-m-${span.m ?? ''}`],
        styles[`span-l-${span.l ?? ''}`],
        styles[`span-xl-${span.xl ?? ''}`],
        {
          [styles[`offset-${props.offset?.s ?? ''}`]]: checkOffsetExists(
            props.offset?.s,
          ),
          [styles[`offset-m-${props.offset?.m ?? ''}`]]: checkOffsetExists(
            props.offset?.m,
          ),
          [styles[`offset-l-${props.offset?.l ?? ''}`]]: checkOffsetExists(
            props.offset?.l,
          ),
          [styles[`offset-xl-${props.offset?.xl ?? ''}`]]: checkOffsetExists(
            props.offset?.xl,
          ),
          [String(props.className)]: !!props.className,
        },
      )}
    >
      {props.children}
    </div>
  );
});
