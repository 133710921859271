'use client';

import { isBrowser } from '@brand/utils';
import { type JSX } from 'react';
import { createPortal } from 'react-dom';

const getContainer = (): HTMLDivElement => {
  let container = document.querySelector('#portal-container') as HTMLDivElement;
  if (!container) {
    container = document.createElement('div');
    container.id = 'portal-container';
    document.body.append(container);
  }
  return container;
};

type TPortalProps = {
  container?: HTMLElement;
  children: JSX.Element;
};

export const Portal = ({
  children,
  container,
}: TPortalProps): JSX.Element | null => {
  return isBrowser()
    ? createPortal(children, container ?? getContainer())
    : null;
};
