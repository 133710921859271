import cn from 'classnames';
import { Icon, SlideUp, Typo } from 'components/ui-kit-v2';
import { useState, type JSX } from 'react';
import styles from './checkbox.module.scss';

export interface ICheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: React.ReactNode;
  /**
   * Текст ошибки
   */
  errorMessage?: string;
  onChecked?(checked: boolean): void;
}

export const Checkbox = ({
  label,
  onChecked,
  className,
  errorMessage,
  ...props
}: ICheckboxProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(props.checked ?? props.value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChecked?.(event.currentTarget.checked);
    setIsChecked(event.currentTarget.checked);
    props.onChange?.(event);
  };

  return (
    <label className={styles.container}>
      <div className={styles.inputWrapper}>
        <input
          type="checkbox"
          {...props}
          className={cn(styles.checkbox, className)}
          onChange={handleChange}
        />
        <Icon
          color={errorMessage ? 'Primitive-Error' : undefined}
          icon={isChecked ? 'IcSquareCheckFill' : 'IcSquareNull'}
        />
      </div>
      <div className={styles.label}>
        <SlideUp isShow={!!errorMessage}>
          <Typo color="Primitive-Error" className="mb-1">
            {errorMessage}
          </Typo>
        </SlideUp>
        {label}
      </div>
    </label>
  );
};
