import { useQueryRouter } from 'components/query-router';
import { Column, Row, Typo } from 'components/ui-kit-v2';
import {
  Modal,
  ModalContent,
  ModalHeader,
} from 'components/ui-kit-v2/modal/modal.component';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { type JSX } from 'react';
import styles from '../../auth-modal.module.scss';
import { LoginForm } from '../../components/login-form.component';

export const LoginRoute = (): JSX.Element => {
  const router = useQueryRouter();
  return (
    <Modal
      onClose={() => router.push('')}
      isOpen={Boolean(router.current === EAuthScreen.LOGIN)}
      contentClassName={styles.modal}
    >
      <ModalHeader>
        <Row>
          <Column>
            <Typo size="2xl">Войти в Универмаг</Typo>
          </Column>
        </Row>
      </ModalHeader>
      <ModalContent>
        <LoginForm onSucces={() => router.push(EAuthScreen.SUCCESS_LOGIN)} />
      </ModalContent>
    </Modal>
  );
};
