import cn from 'classnames';
import { SlideUp } from 'components/ui-kit-v2/slide-up';
import { Typo } from 'components/ui-kit-v2/typo';
import { type JSX } from 'react';
import styles from './label-description.module.scss';
import { TLabelDescriptionProps } from './label.types';

export const LabelDescription = ({
  description,
  errorMessage,
}: TLabelDescriptionProps): JSX.Element => {
  const shouldShow = description !== undefined || errorMessage !== undefined;
  return (
    <SlideUp isShow={shouldShow}>
      <>
        <Typo
          className={cn(styles.content, {
            [styles.isInvalid]: !!errorMessage,
          })}
          type="div"
          size="s"
          color={errorMessage ? 'Primitive-Error' : 'Primitive-Secondary'}
        >
          {errorMessage ?? description}
        </Typo>
      </>
    </SlideUp>
  );
};
