import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcSearch = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50812 3.66663C5.8339 3.66663 3.66602 5.83451 3.66602 8.50873C3.66602 11.183 5.8339 13.3508 8.50812 13.3508C9.84543 13.3508 11.0549 12.8097 11.932 11.9326C12.8091 11.0556 13.3502 9.84605 13.3502 8.50873C13.3502 5.83451 11.1823 3.66663 8.50812 3.66663ZM2.16602 8.50873C2.16602 5.00608 5.00547 2.16663 8.50812 2.16663C12.0108 2.16663 14.8502 5.00608 14.8502 8.50873C14.8502 9.98949 14.342 11.3526 13.4917 12.4316L17.613 16.553L16.5523 17.6136L12.431 13.4923C11.3519 14.3427 9.98887 14.8508 8.50812 14.8508C5.00547 14.8508 2.16602 12.0114 2.16602 8.50873Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
