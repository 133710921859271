import { get } from '@brand/utils';
import { Office } from 'lib/models/office-response.model';
import { SearchAddressModel } from 'lib/models/search-address.response';
import { JsonName, JsonNameReadonly, serialize } from 'tserialize';
import { DeliveryPoint } from '../delivery-points-response.model';

export type TOrderCreateRequest = Omit<OrderCreateRequest, 'toServer'>;
export enum PAYMENT_METHOD {
  UKASSA = 1,
  COMPANY = 2,
}

const mapFrom =
  (key: string) => (_: unknown, instance: Record<string, string>) =>
    get(instance, key);

export class OrderCreateRequest {
  @JsonNameReadonly() type!: 'person' | 'company';

  @JsonNameReadonly() agreement!: boolean;

  /** Адрес доставки который получаем из справочника */
  @JsonNameReadonly() shippingAddress!: SearchAddressModel;

  /** Пункта выдачи */
  @JsonNameReadonly() deliveryPoint?: DeliveryPoint | null;

  /** Офис выдачи */
  @JsonNameReadonly() office?: Office | null;

  /** ID способа оплаты
   * (1 - ЮKassa)
   */
  @JsonName('order[payment_method_id]') paymentMethodId: PAYMENT_METHOD =
    PAYMENT_METHOD.UKASSA;

  /** Имя */
  @JsonName('user[name]') name!: string;

  /** Фамилия */
  @JsonName('user[last_name]') lastName!: string;

  /** Email */
  @JsonName('user[email]') email!: string;

  /** Телефон */
  @JsonName('user[phone]') phone!: string;

  /** Комментарий */
  @JsonName('order[property][comment]') comment?: string;

  /** Комментарий */
  @JsonName('order[property][invoice_email]')
  invoiceEmail?: string;

  /** Комментарий */
  @JsonName(
    'order[property][inn]',
    (value: string, instance: OrderCreateRequest) => {
      if (instance.type === 'company') return value;
      return undefined;
    },
  )
  inn?: string;

  /** Код доставки */
  @JsonName('order[shipping_type_code]') shippingTypeCode!: string;

  /** ID Пункта выдачи маппим из deliveryPoint */
  @JsonName(
    'order[delivery_point_id]',
    (_: number, instance: OrderCreateRequest) => {
      if (!instance.shippingTypeCode.includes('pvz')) {
        return undefined;
      }
      return instance.deliveryPoint?.id;
    },
  )
  protected deliveryPointId?: number;

  /** ID Офиса выдачи маппим из office */
  @JsonName(
    'order[delivery_office_id]',
    (_: number, instance: OrderCreateRequest) => {
      if (!instance.shippingTypeCode.includes('staff')) {
        return undefined;
      }
      return instance.office?.id;
    },
  )
  protected officeId?: number;

  /** Город адреса доставки маппим из справочника shippingAddress */
  @JsonName('shipping_address[country]', mapFrom('shippingAddress.country'))
  protected country?: string;

  /** Регион адреса доставки маппим из справочника shippingAddress */
  @JsonName('shipping_address[state]', mapFrom('shippingAddress.state'))
  protected state?: string;

  /** Город адреса доставки маппим из справочника shippingAddress */
  @JsonName('shipping_address[city]', mapFrom('shippingAddress.city'))
  protected city?: string;

  /** Почтовый индекс адреса доставки маппим из справочника shippingAddress */
  @JsonName('shipping_address[postcode]', mapFrom('shippingAddress.postcode'))
  protected postcode?: string;

  /** Адрес доставки маппим из справочника shippingAddress (Сакко и Ванцетти, 61)*/
  @JsonName('shipping_address[address1]', mapFrom('shippingAddress.address'))
  protected address1!: string;

  /** Полный адрес доставки маппим из справочника shippingAddress */
  @JsonName(
    'shipping_address[address_full]',
    mapFrom('shippingAddress.addressFull'),
  )
  addressFull?: string;

  constructor(data: TOrderCreateRequest) {
    Object.assign(this, data);
  }

  toServer(): OrderCreateRequest {
    return serialize(this) as OrderCreateRequest;
  }
}
