'use client';
import {
  Alert,
  Button,
  Column,
  Link,
  Row,
  Spacer,
  Typo,
} from 'components/ui-kit-v2';
import { CheckboxField } from 'components/ui-kit-v2/form/checkbox/checkbox.field';
import { InputField } from 'components/ui-kit-v2/form/input/input.field';
import { InputPasswordField } from 'components/ui-kit-v2/form/input-password/input-pasword.field';
import { Form, Formik, FormikProps } from 'formik';
import { Profile, RegisterResponse, carrotService } from 'lib';
import { EMAIL_REGEXP, ValidateBirthDate } from 'lib/validators/validators';
import { loginService } from 'modules/login';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { useState, type JSX } from 'react';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  agreement: yup.boolean().oneOf([true], 'Нужно ваше согласие'),
  birthday: yup
    .string()
    .test('ValidDate', 'Проверьте дату', ValidateBirthDate()),
  username: yup.string().required('Это обязательное поле'),
  lastName: yup.string().required('Это обязательное поле'),
  password: yup
    .string()
    .min(8, 'В пароле должно быть хотя бы 8 знаков')
    .max(40, 'Пароль должен быть не больше 40 символов!')
    .required('Это обязательное поле'),
  email: yup
    .string()
    .max(320, 'Проверьте адрес')
    .email('Проверьте адрес')
    .matches(EMAIL_REGEXP, 'Проверьте адрес')
    .required('Это обязательное поле'),
  passwordConfirmation: yup
    .string()
    .required('Это обязательное поле')
    .oneOf([yup.ref('password'), null], 'Пароли не совпали, попробуйте ещё'),
});

export type TFormValues = {
  email: string;
  username: string;
  password: string;
  lastName: string;
  passwordConfirmation: string;
  agreement: boolean;
  birthday: string;
};

const DEFAULT_INITIAL_VALUES: TFormValues = {
  email: '',
  username: '',
  lastName: '',
  password: '',
  passwordConfirmation: '',
  agreement: true,
  birthday: '',
};

type TSignupFormProps = {
  onSuccess: VoidFunction;
};

export const SignupForm = ({ onSuccess }: TSignupFormProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');
  const { invalidateProfile } = useUserInfoContext();

  const handleLogin = async (values: TFormValues): Promise<void> => {
    setErrorMessage('');
    await loginService
      .register(values)
      .then((res: RegisterResponse | undefined) => {
        if (res && res.status) {
          onSuccess?.();
        } else {
          throw new Error(res?.message ?? 'Ошибка при регистрации');
        }
      })
      .then(invalidateProfile)
      .then(async (profile: Profile | null | void) => {
        if (profile) {
          await carrotService.identifyUser({
            userId: profile.id,
            email: profile.email,
            phone: profile.phone,
          });
        }
      })
      .catch((error: Error) => {
        setErrorMessage(error.message);
      });
  };
  return (
    <Formik<TFormValues>
      validationSchema={validationSchema}
      onSubmit={handleLogin}
      initialValues={DEFAULT_INITIAL_VALUES}
    >
      {({ isSubmitting, isValid }: FormikProps<TFormValues>) => (
        <Form placeholder="">
          <Alert visible={Boolean(errorMessage)} className="mb-4">
            {errorMessage}
          </Alert>

          <Row gap={['2x', '0x']}>
            <Column span={12}>
              <InputField name="email" label="Email" />
            </Column>
            <Column span={12}>
              <InputPasswordField name="password" label="Пароль" />
            </Column>
            <Column span={12}>
              <InputPasswordField
                name="passwordConfirmation"
                label="Повторите пароль"
              />
            </Column>
            <Column span={12}>
              <InputField name="username" label="Ваше имя" />
            </Column>
            <Column span={12}>
              <InputField name="lastName" label="Ваша фамилия" />
            </Column>
            <Column span={12}>
              <InputField
                name="birthday"
                label="День рождения"
                mask="99.99.9999"
              />
            </Column>
          </Row>

          <Spacer size="16x" />
          <Row gap={['2x', '2x']}>
            <Column span={12}>
              <Button isBlock isDisabled={isSubmitting || !isValid}>
                Создать
              </Button>
            </Column>
            <Column span={12}>
              <CheckboxField
                name="agreement"
                errorMessage="Нужно ваше согласие"
                label={
                  <Typo size="xs">
                    Создавая личный кабинет, вы&nbsp;соглашаетесь с&nbsp;
                    <Link href="/concent">
                      <Typo size="xs" decoration="underline">
                        условиями обработки персональных данных
                      </Typo>
                    </Link>{' '}
                    и&nbsp;
                    <Link href="/privacy">
                      <Typo size="xs" decoration="underline">
                        политикой конфиденциальности
                      </Typo>
                    </Link>
                  </Typo>
                }
              />
            </Column>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
