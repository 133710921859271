import { HttpClientResponse, delay } from '@brand/utils';
import { Office, OfficeResponse } from 'lib/models/office-response.model';
import { HttpClient } from '../http-client';

export class OfficeTransport {
  constructor(private httpClient: HttpClient) {}

  /**
   * Поиск офисов
   */
  async search(search: string): Promise<Array<Office>> {
    try {
      const { data } = await this.httpClient.get<HttpClientResponse<Office>>(
        '/search/delivery/offices',
        { params: { search } },
      );
      return OfficeResponse.fromServer(data).data;
    } catch (error) {
      return [];
    }
  }
}

export const officeTransport = new OfficeTransport(new HttpClient());
