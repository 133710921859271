import cn from 'classnames';
import { toMediaValue } from 'lib';
import { ReactNode, useMemo, type JSX } from 'react';
import { TBreakpoint } from 'styles/theme';
import styles from './container.module.scss';

type TMediaValue<T> = Partial<Record<TBreakpoint, T>>;
type TContainerVariant = 'default' | 'edge' | 'fluid';

export type TContainerProps = {
  /**
   *
   */
  children: ReactNode;
  /**
   * Пользовательский класс стилей
   */
  className?: string;
  /**
   * Вариант отображения контейнера
   */
  variant?: TContainerVariant | TMediaValue<TContainerVariant>;

  /**
   * Тестовый атрибут
   */
  dataAt?: string;
};

export const Container = (props: TContainerProps): JSX.Element => {
  const variant = toMediaValue<TMediaValue<boolean>>(props.variant, 'default');

  const classes = useMemo(
    () =>
      Object.entries({
        ['variant']: variant,
      }).flatMap(([cssProperty, values]: [string, object]) => {
        return Object.entries(values).map(
          ([media, value]: [string, string]) =>
            styles[`${cssProperty}-${value}-${media}`],
        );
      }),
    [variant],
  );

  return (
    <div
      data-at={props.dataAt}
      className={cn(styles.container, classes, props.className)}
    >
      {props.children}
    </div>
  );
};
