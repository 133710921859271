import variables from './colors.module.scss';

export type TColor =
  | 'Primitive-Primary'
  | 'Primitive-Default'
  | 'Primitive-Secondary'
  | 'Primitive-Brand'
  | 'Primitive-Primary-On-Inverse'
  | 'State-Bg-Inverse-Active'
  | 'Bg-Inverse'
  | 'Bg-Error'
  | 'Bg-Success'
  | 'Popup-Primary'
  | 'Container-Transparent-1'
  | 'State-Container-Transparent-1-Active'
  | 'Accent-green'
  | 'Primitive-Neutral-1'
  | 'Container-Transparent-2'
  | 'Primitive-Neutral-2'
  | 'Primitive-Neutral-4'
  | 'Primitive-Error'
  | 'Translucent-Primitive-Neutral-2';

export const colors = variables as Record<TColor, string>;
