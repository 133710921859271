import variables from './spacing.module.scss';

export type TSpacing =
  | '0x'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x'
  | '11x'
  | '12x'
  | '13x'
  | '14x'
  | '15x'
  | '16x';

export const spacing = variables as Record<TSpacing, string>;
