import {
  AutocompleteField,
  Box,
  Icon,
  SlideUp,
  Spacer,
  Typo,
} from 'components/ui-kit-v2';
import { useFormikContext } from 'formik';
import { organizationTransport } from 'lib';
import { OrganizationModel } from 'lib/models/organization.model';
import { useState, type JSX } from 'react';
import { TOrderFormValues } from '../order-form.const';

export const Company = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<TOrderFormValues>();
  const [selectedOrganization, setSelectedOrganization] =
    useState<OrganizationModel | null>(null);
  const isChooseCompany =
    values.inn === selectedOrganization?.inn && selectedOrganization;
  return (
    <>
      <Spacer size="3x" />
      <AutocompleteField<OrganizationModel>
        size="s"
        name="inn"
        placeholder="Введите ИНН"
        fetcher={(search: string) => organizationTransport.search(search)}
        valueExtractor={(item: OrganizationModel) => item.inn}
        onAutocomplete={setSelectedOrganization}
        rightAccessory={
          isChooseCompany ? <Icon icon="IcCheckIcon" /> : undefined
        }
        renderItem={(item: OrganizationModel) => (
          <>
            {item.inn} {item.name}
          </>
        )}
        keyExtractor={(item: OrganizationModel) => item.inn}
        notFoundElement={
          <Typo size="s">
            Не можем определить юр. лицо. Воспользуйтесь{' '}
            <span
              className="clickable"
              onMouseDown={() => setFieldValue('type', 'person')}
            >
              <Typo decoration="underline">оплатой заказа по карте.</Typo>
            </span>
          </Typo>
        }
      />
      <SlideUp isShow={Boolean(isChooseCompany)}>
        <Box
          variant="flat"
          backgroundColor="Bg-Success"
          className="px-4 py-3 mt-3"
        >
          <Typo type="div" size="s" color="Primitive-Primary-On-Inverse">
            {selectedOrganization?.name}
          </Typo>
          <Typo type="div" size="s" color="Primitive-Primary-On-Inverse">
            ИНН: {selectedOrganization?.inn}
          </Typo>
        </Box>
      </SlideUp>

      <Typo weight="medium" type="div" size="xs" className="mt-8">
        Укажите почту, на&nbsp;которую отправить счёт для оплаты и&nbsp;данные
        того, кто примет заказ
      </Typo>
    </>
  );
};
