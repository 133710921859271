import { Box, Skeleton } from 'components/ui-kit-v2';
import { Button } from 'components/ui-kit-v2/button';
import { Typo } from 'components/ui-kit-v2/typo';
import { Profile } from 'lib';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { useAuthRoutes } from 'modules/auth/use-auth-route';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { MutableRefObject, type JSX } from 'react';
import styles from './auth-aside.module.scss';

type TProfileType = 'univermag-employee' | 'employee' | 'auth-guest' | 'guest';

function getProfileType(profile?: Profile | null): TProfileType {
  if (profile?.isUnivermagEmployee) {
    return 'univermag-employee';
  }

  if (profile?.isEmployee) {
    return 'employee';
  }

  if (profile) {
    return 'auth-guest';
  }

  return 'guest';
}

type TAuthAsideProps = {
  asidePriceRef?: MutableRefObject<HTMLElement | null>;
};

export const AuthAside = ({ asidePriceRef }: TAuthAsideProps): JSX.Element => {
  const { profile, isLoading } = useUserInfoContext();
  const { show } = useAuthRoutes();
  // const { height } = useSizeObserver(asidePriceRef);

  const content: Record<TProfileType, React.ReactNode> = {
    'auth-guest': (
      <Typo size="2xl" type="span">
        Классных покупок!
      </Typo>
    ),
    'guest': (
      <>
        <Typo size="2xl" type="span">
          Зайти в&nbsp;Универмаг, чтобы заполнять меньше данных
        </Typo>
        <Button
          className="mt-16"
          onClick={() => show(EAuthScreen.LOGIN)}
          as="span"
          isBlock
          variant="secondary"
        >
          Зайти
        </Button>
      </>
    ),
    'univermag-employee': (
      <>
        <Typo size="2xl" type="span">
          Цена ниже только для своих
        </Typo>
        <Typo size="l" type="span">
          Пусть обновки принесут вдохновение!
        </Typo>
      </>
    ),
    'employee': (
      <>
        <Typo size="2xl" type="span">
          Часть команды — часть корабля!
        </Typo>
        <Typo size="l" type="span">
          Цена для тебя ниже обычной
        </Typo>
      </>
    ),
  };

  return (
    <Box
      // style={{ height }}
      data-auth-aside
      variant="flat"
      backgroundColor="Accent-green"
      className={styles.box}
    >
      {isLoading ? (
        <div>
          <Skeleton />
          <Skeleton width="70%" />
        </div>
      ) : (
        content[getProfileType(profile)]
      )}
    </Box>
  );
};
