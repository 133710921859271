import { ShippingType } from 'modules/cart/api';
import React, { createContext, useContext, useState } from 'react';

type TDeliveryContext = {
  delivery: ShippingType | undefined;
  setDelivery: (data: ShippingType) => void;
};

const INITIAL_DELIVERY_PROVIDER: TDeliveryContext = {
  delivery: undefined,
  setDelivery: () => void 0,
};

const DeliveryContext = createContext(INITIAL_DELIVERY_PROVIDER);

type TDeliveryProviderProps = {
  initialDelivery?: ShippingType;
} & React.PropsWithChildren;

export const DeliveryProvider = ({
  children,
  initialDelivery,
}: TDeliveryProviderProps): JSX.Element => {
  const [deliveryType, setDeliveryType] = useState<ShippingType | undefined>(
    initialDelivery,
  );

  return (
    <DeliveryContext.Provider
      value={{ delivery: deliveryType, setDelivery: setDeliveryType }}
    >
      {children}
    </DeliveryContext.Provider>
  );
};

export const useDeliveryTypeContext = (): TDeliveryContext =>
  useContext(DeliveryContext);
