'use client';
import { useQueryRouter } from 'components/query-router';
import {
  Alert,
  Button,
  Clickable,
  Column,
  Row,
  Spacer,
  Typo,
} from 'components/ui-kit-v2';
import { InputField } from 'components/ui-kit-v2/form/input/input.field';
import { InputPasswordField } from 'components/ui-kit-v2/form/input-password/input-pasword.field';
import { Form, Formik, FormikProps } from 'formik';
import { Profile, authService, carrotService } from 'lib';
import { EMAIL_REGEXP } from 'lib/validators/validators';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { useState, type JSX } from 'react';
import * as yup from 'yup';
import { TochkaEmployeeSigninButton } from './tochka-employee-signin-button.component';
import { TochkaIdSigninButton } from './tochka-id-signin-button.component';
import { EAuthScreen } from '../auth-modal.const';
const validationSchema = yup.object().shape({
  password: yup.string().required('Поле является обязательным'),
  login: yup
    .string()
    .email('Проверьте адрес')
    .matches(EMAIL_REGEXP, 'Проверьте адрес')
    .required('Проверьте адрес'),
});

type TFormValues = {
  login: string;
  password: string;
};

type TLoginFormProps = {
  onSucces?: VoidFunction;
};
export const LoginForm = ({ onSucces }: TLoginFormProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<JSX.Element | null>(null);
  const { invalidateProfile } = useUserInfoContext();
  const router = useQueryRouter();

  const handleLogin = async (values: TFormValues): Promise<void> => {
    setErrorMessage(null);
    await authService
      .login(values.login, values.password)
      .then(invalidateProfile)
      .then(async (user: Profile | null | void) => {
        if (user) {
          await carrotService.restart().then(() =>
            carrotService.identifyUser({
              userId: user.id,
              email: user.email,
              phone: user.phone,
            }),
          );
        }
        onSucces?.();
        return user;
      })
      .catch(() =>
        setErrorMessage(
          <>
            Попробуйте ещё раз или{' '}
            <Clickable
              className="text-decoration-underline"
              onClick={() => router.push(EAuthScreen.FORGOT_PASSWORD)}
            >
              восстановите пароль
            </Clickable>
          </>,
        ),
      );
  };
  return (
    <Formik<TFormValues>
      validationSchema={validationSchema}
      onSubmit={handleLogin}
      initialValues={{
        login: '',
        password: '',
      }}
    >
      {({ isSubmitting, isValid }: FormikProps<TFormValues>) => (
        <Form placeholder="">
          <Alert visible={Boolean(errorMessage)} className="mb-4">
            {errorMessage}
          </Alert>

          <Row gap={['2x', '0x']}>
            <Column span={12}>
              <InputField name="login" label="Email" />
            </Column>
            <Column span={12}>
              <InputPasswordField name="password" label="Пароль" />
            </Column>
          </Row>

          <Row justify="flex-end" className="my-4" gap={['2x', '3x']}>
            <Column span={{ s: 'auto' }}>
              <Typo align="right" weight="medium">
                <Clickable
                  onClick={() => router.push(EAuthScreen.FORGOT_PASSWORD)}
                >
                  Не помните пароль?
                </Clickable>
              </Typo>
            </Column>
            <Column span={12}>
              <Button isBlock isLoading={isSubmitting} isDisabled={!isValid}>
                Войти
              </Button>
            </Column>
          </Row>
          <Spacer size="12x" />
          <Row gap="2x">
            <Column span={6}>
              <TochkaIdSigninButton
                onSuccess={() => router.push(EAuthScreen.SUCCESS_LOGIN)}
              >
                Войти через <br /> Точка&nbsp;ID
              </TochkaIdSigninButton>
            </Column>
            <Column span={6}>
              <TochkaEmployeeSigninButton
                onSuccess={() => router.push(EAuthScreen.SUCCESS_LOGIN)}
              >
                Войти как <br /> сотрудник Точки
              </TochkaEmployeeSigninButton>
            </Column>
            <Column span={12}>
              <Button
                type="button"
                isBlock
                variant="ghost"
                onClick={() => router.push(EAuthScreen.SIGNUP)}
              >
                Создать личный кабинет
              </Button>
            </Column>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
