import cn from 'classnames';
import { Typo } from 'components/ui-kit-v2/typo';
import { memo, type JSX } from 'react';
import styles from './label-text.module.scss';
import { TLabelTextProps } from './label.types';

export const LabelText = memo(
  ({
    label,
    tooltip,
    hasAccessory,
    disabled,
    isFocused,
  }: TLabelTextProps): JSX.Element | null => {
    if (!label) {
      return null;
    }

    return (
      <div className={styles.container}>
        <Typo
          type="div"
          size={isFocused ? '2xs' : 's'}
          color="Primitive-Secondary"
          className={cn(tooltip, styles.label, {
            [styles.disabled]: disabled,
            [styles.hasAccessory]: hasAccessory,
            [styles.focus]: isFocused,
          })}
        >
          {label}
        </Typo>
        {tooltip}
      </div>
    );
  },
);

LabelText.displayName = 'LabelText';
