import { useDebounce } from '@brand/utils';
import {
  Button,
  Icon,
  Input,
  Modal,
  Scrollable,
  Typo,
} from 'components/ui-kit-v2';
import {
  ModalContent,
  ModalHeader,
} from 'components/ui-kit-v2/modal/modal.component';
import { Office } from 'lib/models/office-response.model';
import { officeService } from 'lib/services/office.service';
import { ChangeEvent, useState, type JSX } from 'react';
import useSWR from 'swr';
import styles from './office-modal.module.scss';

export type TOfficeModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onSelect: (office: Office) => void;
};

export const OfficeModal = ({
  isOpen,
  onClose,
  onSelect,
}: TOfficeModalProps): JSX.Element => {
  const [search, setSearch] = useState('');
  const searchQuery = useDebounce(search);
  const { data: offices = [] } = useSWR(
    [searchQuery, officeService.search.name],
    ([query]: Array<string>) => officeService.search(query),
    { keepPreviousData: true, fallbackData: [] },
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>
        <Typo size="xl">Выбрать офис</Typo>
      </ModalHeader>
      <ModalContent>
        <Input
          size="s"
          placeholder="Город или улица с нужным офисом Точки"
          leftAccessory={<Icon icon="IcSearch" />}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setSearch(event.target.value)
          }
        />
        <Scrollable maxHeight={350} className={styles.content}>
          {offices?.map((office: Office) => (
            <div
              className={styles.item}
              key={office.id}
              onClick={() => onSelect(office)}
            >
              <Typo type="div">{office.city}</Typo>
              <Typo type="div" color="Primitive-Secondary">
                {office.address}
              </Typo>
            </div>
          ))}
        </Scrollable>
        <Button variant="secondary" onClick={onClose} isBlock>
          Закрыть
        </Button>
      </ModalContent>
    </Modal>
  );
};
