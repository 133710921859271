'use client';
import { PropsWithChildren } from 'react';
import { useQueryRouter } from './query-router.component';

export type TStackRouteProps = {
  screen: string;
} & PropsWithChildren;

export function QueryRoute(props: TStackRouteProps): React.ReactNode {
  const router = useQueryRouter();

  return router.current === props.screen ? props.children : null;
}
