import { useQueryRouter } from 'components/query-router';
import { Button, Typo } from 'components/ui-kit-v2';
import {
  Modal,
  ModalContent,
  ModalHeader,
} from 'components/ui-kit-v2/modal/modal.component';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { useRouter } from 'next/navigation';
import { type JSX } from 'react';
import styles from '../../auth-modal.module.scss';

export const SuccessRestorePasswordRoute = (): JSX.Element => {
  const router = useRouter();
  const queryRouter = useQueryRouter();
  return (
    <Modal
      contentClassName={styles.modal}
      onClose={() => queryRouter.push('')}
      isOpen={Boolean(
        queryRouter.current === EAuthScreen.SUCCESS_RESTORE_PASSWORD,
      )}
    >
      <ModalHeader>
        <Typo size="xl">Пароль обновлён, классных вам покупок!</Typo>
      </ModalHeader>
      <ModalContent>
        <Button isBlock onClick={() => router.push('/')} className="mt-6">
          Спасибо
        </Button>
      </ModalContent>
    </Modal>
  );
};
