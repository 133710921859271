import { OfferPrices } from 'lib';
import { JsonName, JsonArray, JsonStruct } from 'tserialize';
import { OfferImageModel } from './offer-image.model';
import { ProductPropertyModel } from './product-property.model';

// TODO: добавить JSDOC моделям после описания их в swagger
export class CartOfferModel {
  @JsonName('preview_image') previewImage!: string;

  @JsonArray(OfferImageModel, 'images') images: Array<OfferImageModel> = [];

  @JsonName('price_value') priceValue!: number;

  @JsonName('old_price_value') oldPriceValue!: number;

  @JsonName() currency!: string;

  @JsonStruct(OfferPrices) prices!: OfferPrices;

  @JsonArray(ProductPropertyModel) property: Array<ProductPropertyModel> = [];

  @JsonName('updated_at') updatedAt!: string | null;

  @JsonName('created_at') createdAt!: string | null;

  @JsonName() active!: boolean;

  @JsonName() code!: string;

  @JsonName('currency_code') currencyCode!: string;

  @JsonName() description!: string;

  @JsonName('external_id') externalId!: string | null;

  @JsonName() height!: string | null;

  @JsonName() id!: number;

  @JsonName() length!: string | null;

  @JsonName('measure_id') measureId!: string | null;

  @JsonName('measure_of_unit_id') measureOfUnitId!: string | null;

  @JsonName() name!: string;

  @JsonName('preview_text') previewText!: string;

  @JsonName() price!: string;

  @JsonName('old_price') oldPrice!: string;

  @JsonName('product_id') productId!: number;

  @JsonName() quantity!: number;

  @JsonName('quantity_in_unit') quantityInUnit!: string;

  @JsonName() weight!: number | null;

  @JsonName() width!: number | null;
}
