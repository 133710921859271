import { useQueryRouter } from 'components/query-router';
import { Typo, Button } from 'components/ui-kit-v2';
import {
  Modal,
  ModalContent,
  ModalHeader,
} from 'components/ui-kit-v2/modal/modal.component';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { type JSX } from 'react';
import styles from '../../auth-modal.module.scss';

export const SuccessSignupRoute = (): JSX.Element => {
  const router = useQueryRouter();
  return (
    <Modal
      onClose={() => router.push('')}
      isOpen={Boolean(router.current === EAuthScreen.SUCCESS_SIGNUP)}
      contentClassName={styles.modal}
    >
      <ModalHeader>
        <Typo size="xl">Классных покупок!</Typo>
      </ModalHeader>
      <ModalContent>
        <Button isBlock onClick={() => router.push('')} className="mt-6">
          Спасибо
        </Button>
      </ModalContent>
    </Modal>
  );
};
