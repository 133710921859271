import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcSquareCheckFill = ({
  color,
  className,
}: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.25 2.75H2.75004C2.75098 8.91667 2.75117 15.0833 2.75 21.25H21.2501C21.2501 19.349 21.2502 17.4481 21.2502 15.5471C21.2504 11.2814 21.2505 7.01571 21.25 2.75ZM17.0303 9.53033L15.9697 8.46967L10.5 13.9393L8.03033 11.4697L6.96967 12.5303L9.96967 15.5303L10.5 16.0607L11.0303 15.5303L17.0303 9.53033Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
