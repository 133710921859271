import { JsonName } from 'tserialize';

// TODO: добавить JSDOC моделям после описания их в swagger
export class OfferImageModel {
  @JsonName() thumb!: string;

  @JsonName() path!: string;

  @JsonName('file_name') fileName!: string;

  @JsonName() ext!: string;

  @JsonName('updated_at') updatedAt!: string | null;

  @JsonName('created_at') createdAt!: string | null;

  @JsonName('disk_name') diskName!: string;

  @JsonName() title!: string;

  @JsonName() description!: string | null;
}
