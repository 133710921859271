import cn from 'classnames';
import { ForwardedRef, forwardRef, type JSX } from 'react';
import { LabelDescription } from './label-description.component';
import { LabelText } from './label-text.component';
import styles from './label.module.scss';
import { TLabelProps } from './label.types';

// Врапер для элементов форм, использовать только для создания компонентов форм
export const Label = forwardRef(
  (
    {
      description,
      children,
      label,
      tooltip,
      errorMessage,
      leftAccessory: leftAccesory,
      disabled,
      className,
      isFocused,
      fitHeight,
      size = 'm',
    }: TLabelProps,
    ref: ForwardedRef<HTMLLabelElement | null>,
  ): JSX.Element => {
    return (
      <label
        ref={ref}
        className={cn(
          styles.container,
          styles[`size_${size}`],
          {
            [styles.disabled]: disabled,
            [styles.isInvalid]: !!errorMessage,
            [styles.focus]: isFocused,
            [styles.fitHeight]: fitHeight,
          },
          className,
        )}
      >
        {!!label && (
          <LabelText
            isFocused={isFocused}
            errorMessage={errorMessage}
            label={label}
            disabled={disabled}
            tooltip={tooltip}
            hasAccessory={Boolean(leftAccesory)}
          />
        )}
        {children}
        <LabelDescription
          errorMessage={errorMessage}
          description={description}
          disabled={disabled}
        />
      </label>
    );
  },
);
