import { Breakpoint } from '_shared';
import cn from 'classnames';
import { Visibility } from 'components/ui-kit/visibility';
import {
  Clickable,
  Column,
  Icon,
  Input,
  Row,
  Scrollable,
  Typo,
} from 'components/ui-kit-v2';
import { useModal } from 'components/ui-kit-v2/modal/modal.component';
import { useMedia } from 'hooks';
import { DeliveryPoint } from 'modules/cart/api/models/delivery-points-response.model';
import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
  type JSX,
} from 'react';
import { PvzListItem } from './pvz-list-item';
import styles from './pvz-list.module.scss';

type TPvzListProps = {
  onSelect: (delivery: DeliveryPoint) => void;
  onSearch: (search: string) => void;
  onPointClick: (delivery: DeliveryPoint) => void;
  points: Array<DeliveryPoint>;
};

export type TPvzListRef = {
  selectPoint: (point: DeliveryPoint | null) => void;
};
export const PvzList = forwardRef(
  (
    { onSearch, onPointClick, onSelect, points }: TPvzListProps,
    ref: ForwardedRef<TPvzListRef>,
  ): JSX.Element => {
    const { close } = useModal();
    const breakpoint = useMedia();
    const isMobile = breakpoint < Breakpoint.MEDIUM_SCREEN;
    const [selectedPoint, setSelectedPoint] = useState<DeliveryPoint | null>(
      null,
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          selectPoint(point: DeliveryPoint | null) {
            setSelectedPoint(point);
          },
        };
      },
      [],
    );

    const handlePointClick = (point: DeliveryPoint): void => {
      setSelectedPoint(point);
      onPointClick(point);
    };

    return (
      <div className={styles.listWrapper}>
        <div className={styles.listHeader}>
          <Visibility visible="m">
            <Row>
              <Column>
                <Clickable onClick={close}>
                  <Icon icon="IcCross" />
                </Clickable>
              </Column>
              <Column>
                <Typo size="xl" className="mx-3">
                  Выбор пункта выдачи
                </Typo>
              </Column>
            </Row>
          </Visibility>

          <Row className="mt-0 mt-m-4 pb-m-0">
            <Column span={12}>
              <Input
                placeholder="Ближайший адрес или метро"
                size="s"
                leftAccessory={<Icon icon="IcSearch" />}
                className="mt-3"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onSearch(event.currentTarget.value)
                }
              />
            </Column>
          </Row>
        </div>
        <div className={styles.list}>
          <Scrollable
            hideHorizontal
            maxHeight={isMobile ? styles.listHeightMobile : styles.listHeight}
            className="mt-4 mt-m-8 pb-4 pb-m-0 px-4"
          >
            <Row gap={['0x', '3x']}>
              {points.map((point: DeliveryPoint) => (
                <Column span={12} key={point.id}>
                  <Clickable onClick={() => handlePointClick(point)}>
                    <span className="d-block">
                      <Typo type="div" size="s">
                        Пункт выдачи - {point.name}
                      </Typo>
                      <Typo type="div" size="s" color="Primitive-Secondary">
                        {point.address}
                      </Typo>
                    </span>
                  </Clickable>
                </Column>
              ))}
            </Row>
          </Scrollable>
        </div>
        <div
          className={cn(styles.selectedPointWrapper, {
            [styles.isSelected]: !!selectedPoint,
          })}
        >
          {selectedPoint && (
            <PvzListItem
              onBack={() => setSelectedPoint(null)}
              point={selectedPoint}
              onSelect={onSelect}
            />
          )}
        </div>
      </div>
    );
  },
);
