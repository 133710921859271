'use client';
import { useQueryRouter } from 'components/query-router';
import { Modal, Spacer, Typo } from 'components/ui-kit-v2';
import { ModalContent } from 'components/ui-kit-v2/modal/modal.component';
import { TochkaEmployeeSigninButton } from 'modules/auth/components/tochka-employee-signin-button.component';
import { type JSX } from 'react';
import { EAuthScreen } from '../../auth-modal.const';
import styles from '../../auth-modal.module.scss';

export const LooksLikeEmployeeRoute = (): JSX.Element => {
  const queryRouter = useQueryRouter();

  return (
    <Modal
      contentClassName={styles.modal}
      isOpen={Boolean(queryRouter.current === EAuthScreen.LOOKS_LIKE_EMPLOYEE)}
    >
      <Typo size="xl">Похоже, ты из Точки!</Typo>
      <ModalContent>
        <Typo type="div" size="l" className="mt-11">
          Для заказа войди, как в&nbsp;Неработе. <br />
          Сразу после авторизации вернёмся к&nbsp;оформлению заказа.
        </Typo>
        <Spacer size="11x" />´{' '}
        <TochkaEmployeeSigninButton
          size="m"
          onSuccess={() =>
            queryRouter.push(EAuthScreen.LOOKS_LIKE_EMPLOYEE_SUCCESS)
          }
        />
      </ModalContent>
    </Modal>
  );
};
