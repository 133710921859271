import cn from 'classnames';
import { PropsWithChildren, type JSX } from 'react';
import { TColor } from 'styles/theme';
import { Box } from '../box';
import { SlideUp } from '../slide-up';
import { Typo } from '../typo';
import { TTypoProps } from '../typo/typo.component';

type TAlertType = 'success' | 'error' | 'neutral' | 'primary';
type TAlertProps = {
  /** Показывает/скрывает блок */
  visible?: boolean;
  className?: string;
  type?: TAlertType;
  align?: TTypoProps['align'];
};

const colorMap: Record<TAlertType, [TColor, TColor]> = {
  error: ['Bg-Error', 'Primitive-Default'],
  success: ['Bg-Success', 'Primitive-Default'],
  neutral: ['Primitive-Neutral-2', 'Primitive-Primary'],
  primary: ['Primitive-Primary', 'Primitive-Primary-On-Inverse'],
};

export const Alert = ({
  children,
  className,
  visible = true,
  type = 'error',
  align = 'center',
}: PropsWithChildren<TAlertProps>): JSX.Element => {
  const [background, color] = colorMap[type];

  return (
    <SlideUp isShow={visible}>
      <Box
        variant="flat"
        backgroundColor={background}
        className={cn('py-3 px-4', className)}
      >
        <Typo color={color} align={align}>
          {children}
        </Typo>
      </Box>
    </SlideUp>
  );
};
