import { Column, Row, Skeleton } from 'components/ui-kit-v2';
import { type JSX } from 'react';

export function CartPositionItemSkeleton(): JSX.Element {
  return (
    <Row>
      <Column span={{ s: 4, m: 3 }}>
        <Skeleton height="70px" />
      </Column>

      <Column span={{ s: 8, m: 9 }}>
        <Row className="h-100" direction="column" justify="space-between">
          <Column>
            <Skeleton width="150px" />
            <Skeleton width="50px" />
          </Column>
        </Row>
      </Column>
    </Row>
  );
}
