import {
  AutocompleteField,
  Column,
  Row,
  Spacer,
  Typo,
} from 'components/ui-kit-v2';
import { useFormikContext } from 'formik';
import { geoService } from 'lib';
import { SearchAddressModel } from 'lib/models/search-address.response';
import { useUserInfoContext } from 'providers/auth-provider/user-info-provider';
import { ChangeEvent, type JSX } from 'react';
import { TOrderFormValues, initialValues } from '../order-form.const';

export const Address = (): JSX.Element => {
  const { setFieldValue } = useFormikContext<TOrderFormValues>();
  const { profile } = useUserInfoContext();

  const placeholder =
    'Начните с города, затем улица и всё остальное' +
    (profile?.isEmployee
      ? '. А если из офиса Точки — сразу жми самовывоз'
      : '');

  return (
    <>
      <Row gap={['0x', '2x']}>
        <Column span={12}>
          <Typo size="2xl">Данные для доставки</Typo>
        </Column>
      </Row>
      <Spacer size="15x" />
      <Spacer size="15x" />
      <Typo size="l" weight="medium">
        Куда
      </Typo>
      <Spacer size="3x" />
      <AutocompleteField<SearchAddressModel>
        size="s"
        name="shippingAddress.addressFull"
        aria-autocomplete="none"
        autoComplete="new-password"
        placeholder={placeholder}
        fetcher={(search: string) => geoService.searchAddress(search)}
        valueExtractor={(item: SearchAddressModel) => item.addressFull}
        renderItem={(item: SearchAddressModel) => <>{item.addressFull}</>}
        keyExtractor={(item: SearchAddressModel) => item.addressFull}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setFieldValue('shippingAddress', {
            ...initialValues.shippingAddress,
            addressFull: event.currentTarget.value,
          })
        }
        onAutocomplete={(item: SearchAddressModel) =>
          setFieldValue('shippingAddress', item)
        }
        // addElement={
        //   <div className="d-flex">
        //     <Icon icon="IcPlus" />
        //     <Typo className="mx-3">Новый адрес</Typo>
        //   </div>
        // }
      />
    </>
  );
};
