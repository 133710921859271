import { useToggle } from '@brand/utils';
import {
  Clickable,
  Column,
  Icon,
  Row,
  Scrollable,
  SkeletonList,
  Typo,
} from 'components/ui-kit-v2';
import { MoneyFormatter, PluralizeFormatter } from 'lib';
import { CartPositions as CartPositionsModel } from 'modules/cart/api';
import { useCartContext } from 'modules/cart/providers';
import { CSSProperties, type JSX } from 'react';
import { CartPositionItem } from './cart-position-item.component';
import { CartPositionItemSkeleton } from './cart-position-item.skeleton';
import styles from './cart-positions.module.scss';

export const CartPositions = (): JSX.Element => {
  const { cart, cartIsLoading } = useCartContext();
  const [isOpen, toggle] = useToggle(true);
  const positions = cart?.positions ?? [];

  const productsCountPluralize = PluralizeFormatter.format(positions.length, {
    one: 'Товар',
    few: 'Товара',
    many: 'Товаров',
  });

  if (cartIsLoading) {
    return (
      <SkeletonList
        count={3}
        renderItem={
          <Column span={12}>
            <CartPositionItemSkeleton />
          </Column>
        }
      />
    );
  }

  return (
    <>
      <Row gap={{ s: ['1x', '2x'] }} justify={{ s: 'space-between' }}>
        <Column>
          <Clickable onClick={toggle} className="d-flex">
            <Typo size="l" weight="medium">
              {positions?.length} {productsCountPluralize}
            </Typo>
            <Icon
              icon={isOpen ? 'IcShevronUp' : 'IcShevronDown'}
              className="mx-2"
            />
          </Clickable>
        </Column>
        <Column>
          <Typo size="l" weight="medium">
            {MoneyFormatter.format(cart?.totalPrice?.totalPrice ?? 0)}
          </Typo>
        </Column>
      </Row>
      <div
        className={styles.wrapper}
        style={
          {
            '--total-items': isOpen ? positions.length : 0,
          } as CSSProperties
        }
      >
        <Scrollable maxHeight={340} className="my-3" hideHorizontal>
          <Row gap={['0x', '3x']}>
            {positions.map((position: CartPositionsModel) => (
              <Column span={12} key={position.id}>
                <CartPositionItem position={position} />
              </Column>
            ))}
          </Row>
        </Scrollable>
      </div>
    </>
  );
};
