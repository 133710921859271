import { motion } from 'framer-motion';
import { type JSX, type PropsWithChildren } from 'react';

type TSlideUpProps = {
  /**
   * Показывать/скрыть контент
   */
  isShow?: boolean;
  className?: string;
} & PropsWithChildren;

export const SlideUp = ({
  isShow,
  children,
  className,
}: TSlideUpProps): JSX.Element => {
  const variants = {
    hidden: { height: 0, opacity: 0 },
    visible: { height: 'auto', opacity: 1 },
  };

  return (
    <motion.div
      initial="hidden"
      animate={isShow ? 'visible' : 'hidden'}
      variants={variants}
      transition={{ duration: 0.3, easings: 'easeIn' }}
      style={{ overflow: 'hidden' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};
