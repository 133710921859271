import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcEyeClose = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1367 9.94769C16.563 9.49138 16.9453 8.98745 17.2758 8.44373L18.5576 9.22293C18.1863 9.83359 17.758 10.4023 17.2802 10.9205L18.2977 12.0685L17.1752 13.0634L16.1866 11.9481C15.3497 12.6276 14.4083 13.1737 13.3909 13.5541L13.7717 14.8497L12.3326 15.2727L11.9493 13.9688C10.6662 14.2315 9.33308 14.2314 8.04999 13.9687L7.66762 15.2723L6.22826 14.8501L6.60847 13.5539C5.59108 13.1733 4.64976 12.6272 3.81287 11.9476L2.82508 13.0632L1.70208 12.0688L2.71932 10.92C2.24176 10.4019 1.8136 9.83337 1.4425 9.22293L2.72424 8.44373C3.05478 8.98745 3.43711 9.49138 3.86342 9.94769C3.99188 10.0852 4.12432 10.2183 4.26053 10.347C5.24913 11.2805 6.43536 11.9738 7.73941 12.3489C9.20778 12.7713 10.7912 12.7714 12.2596 12.3492C13.5638 11.9743 14.7501 11.2811 15.7388 10.3477C15.8753 10.2188 16.008 10.0854 16.1367 9.94769Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
