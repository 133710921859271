import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcMinus = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 11.75H4.5H19.5H20.25V13.25H19.5H4.5H3.75V11.75Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
