import { debounce, useOnClickOutside } from '@brand/utils';
import cn from 'classnames';
import { Scrollable } from 'components/ui-kit-v2';
import { Spinner } from 'components/ui-kit-v2/spinner';
import { useCallback, useRef, useState, type JSX } from 'react';
import styles from './autocomplete.module.scss';
import { useKeyboardNav } from './use-keyboard-nav';
import { Label } from '../label';
import { TLabelProps } from '../label/label.types';

export interface IAutocompleteProps<T>
  extends Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'formAction' | 'children' | 'size'
    >,
    Omit<TLabelProps, 'children' | 'isFocused'> {
  /**
   * Компонент который будет показан если список пуст
   */
  notFoundElement?: JSX.Element;
  /**
   * Компонент добавление элементов в список, рендерится последним
   */
  addElement?: JSX.Element;
  /**
   * Событие применения автокомлита
   * @param arg T
   * @returns void
   */
  onAutocomplete?: (arg: T) => void;
  /**
   * Функция получения элементов списка
   * @param search {string}
   * @returns
   */
  fetcher: (search: string) => Promise<Array<T>>;
  /**
   * Функция извлечения значение для вставки в инпут
   * @param arg T
   * @returns
   */
  valueExtractor: (arg: T) => unknown;
  /**
   * Рендер функция элемента списка
   * @param arg
   * @returns
   */
  renderItem: (arg: T) => JSX.Element;
  /**
   * Функция извлечения ключа(key) для элемента списка
   * @param arg
   * @returns
   */
  keyExtractor: (arg: T) => string;
}

export function Autocomplete<T>({
  label,
  tooltip,
  description,
  errorMessage,
  leftAccessory,
  rightAccessory,
  className,
  disabled,
  size = 'm',
  fetcher,
  valueExtractor,
  renderItem,
  keyExtractor,
  onAutocomplete,
  notFoundElement,
  addElement,
  fitHeight,
  ...props
}: IAutocompleteProps<T>): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isVisibleSuggestions, setIsVisibleSuggestions] = useState(false);
  const [list, setList] = useState<Array<T>>([]);
  const isActive = isFocused || Boolean(props.value);
  const inputWrapper = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLInputElement>(null);
  const refList = useRef<HTMLDivElement>(null);
  const { selectedIndex, onKeyDown, resetIndex } = useKeyboardNav<T>(
    list,
    handleClick,
  );

  const labelProps: Omit<TLabelProps, 'children'> = {
    size,
    label,
    tooltip,
    leftAccessory,
    rightAccessory,
    description,
    errorMessage,
    disabled,
    className,
    isFocused: isActive,
    fitHeight,
  };

  const inputProps = {
    ...props,
    type: 'text',
    disabled,
    ref: ref,
    className: cn(styles.input, { [styles.active]: isActive }),
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      props?.onChange?.(event);

      if ((event.nativeEvent as CustomEvent).detail !== 'emitter') {
        fetchData();
      }
    },
    onFocus: (event: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(true);
      props.onFocus?.(event);
    },
    onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => {
      // setIsVisibleSuggestions(false);
      setIsFocused(false);
      props.onBlur?.(event);
    },
  };

  useOnClickOutside(inputWrapper, () => {
    setIsVisibleSuggestions(false);
    setIsFocused(false);
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(
    debounce(() => {
      resetIndex();
      setIsLoading(true);
      void fetcher(ref.current?.value ?? '')
        .then(setList)
        .then(() => {
          setIsVisibleSuggestions(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, 300),
    [ref],
  );

  function handleClick(item: T): void {
    if (ref.current) {
      Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      )?.set?.call(ref.current, valueExtractor(item));
      ref.current.dispatchEvent(
        new CustomEvent<string>('input', { bubbles: true, detail: 'emitter' }),
      );
      resetIndex();
      setIsVisibleSuggestions(false);
    }
    onAutocomplete?.(item);
  }

  const isShowNotFound = list.length < 1 && !isLoading;

  return (
    <div className={styles.wrapper} ref={inputWrapper}>
      <Label {...labelProps}>
        <div
          onKeyDown={onKeyDown}
          className={cn(styles.content, {
            [styles.active]: isActive && label,
          })}
        >
          {leftAccessory}
          <input {...inputProps} />
          {isLoading ? <Spinner size={20} /> : rightAccessory}
        </div>
      </Label>
      <Scrollable
        ref={refList}
        maxHeight={220}
        className={cn(styles.suggestions, {
          [styles.suggestionsVisible]: isVisibleSuggestions && !isLoading,
        })}
      >
        {list.map((item: T, index: number) => (
          <div
            tabIndex={0}
            ref={(instance: HTMLDivElement | null) => {
              if (instance && selectedIndex === index) {
                refList?.current?.scrollTo(0, instance.offsetTop);
              }
            }}
            className={cn(styles.suggestion, {
              [styles.suggestionActive]: selectedIndex === index,
            })}
            key={keyExtractor(item)}
            onMouseDown={() => handleClick(item)}
          >
            {renderItem(item)}
          </div>
        ))}

        {isShowNotFound && (
          <div className={styles.notFoundElement}>{notFoundElement}</div>
        )}

        {addElement && <div className={styles.addElement}>{addElement}</div>}
      </Scrollable>
    </div>
  );
}
