import { QueryRoute } from 'components/query-router';
import { Box, Clickable, Icon, Typo } from 'components/ui-kit-v2';
import { EAuthScreen } from 'modules/auth/auth-modal.const';
import { useAuthRoutes } from 'modules/auth/use-auth-route';
import { useCallback, useState, type JSX } from 'react';
import styles from './should-change-psasword.module.scss';

export const ShouldChangePasswordRoute = (): JSX.Element | null => {
  const [isOpen, setIsOpen] = useState(true);
  const { show } = useAuthRoutes();

  const showRestorePasswordModal = useCallback(() => {
    show(EAuthScreen.RESTORE_PASSWORD);
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <QueryRoute screen={EAuthScreen.SHOULD_CHANGE_PASSWORD}>
      <Box
        className={styles.container}
        variant="flat"
        backgroundColor="Primitive-Primary"
      >
        <Clickable
          onClick={showRestorePasswordModal}
          className={styles.content}
        >
          <Typo
            size="s"
            color="Primitive-Primary-On-Inverse"
            className={styles.content}
          >
            Придумайте новый пароль
            <Icon icon="IcArrowRight" className="mx-4" />
          </Typo>
        </Clickable>
        <Clickable onClick={() => setIsOpen(false)} className={styles.close}>
          <Icon icon="IcCross" color="Primitive-Primary-On-Inverse" />
        </Clickable>
      </Box>
    </QueryRoute>
  );
};
