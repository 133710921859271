import { isObject } from '@brand/utils';
import cn from 'classnames';
import React, { memo, useMemo, type JSX } from 'react';
import { spacing, TBreakpoint, TSpacing } from 'styles/theme';
import styles from './spacer.module.scss';

type TMediaValue<T> = Partial<Record<TBreakpoint, T>>;

export type TSpacerProps = {
  /**
   * Размер отступа
   */
  size: TSpacing | TMediaValue<TSpacing>;
};

export const Spacer = memo(({ size }: TSpacerProps): JSX.Element => {
  const sizes = isObject(size) ? size : { s: size };
  const sizeVars = useMemo(
    () =>
      Object.entries(sizes).reduce(
        (acc: Record<string, string>, [media, value]: [string, TSpacing]) => {
          acc[`--height-${media}`] = spacing[value];
          return acc;
        },
        {},
      ),
    [size],
  );

  return (
    <div
      style={sizeVars}
      className={cn(
        styles.spacer,
        Object.keys(sizes).map((media: string) => styles[`spacer-${media}`]),
      )}
    />
  );
});
Spacer.displayName = 'Spacer';
