import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcShevronDown = ({
  color,
  className,
}: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29201 8.45993L4.91605 8.87596L12 13.5986L19.084 8.87596L19.708 8.45993L20.5401 9.70801L19.916 10.124L12.416 15.124L12 15.4014L11.584 15.124L4.084 10.124L3.45996 9.70801L4.29201 8.45993Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
