import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcCross = ({ color, className }: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.50011 5.77307L7.03044 6.3034L12.0001 11.2731L16.9698 6.3034L17.5001 5.77307L18.5608 6.83373L18.0304 7.36406L13.0608 12.3337L18.0304 17.3034L18.5608 17.8337L17.5001 18.8944L16.9698 18.3641L12.0001 13.3944L7.03044 18.3641L6.50011 18.8944L5.43945 17.8337L5.96978 17.3034L10.9395 12.3337L5.96978 7.36406L5.43945 6.83373L6.50011 5.77307Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
