import { type JSX } from 'react';
import { colors } from 'styles/theme';
import { TIconProps } from '../icon';

export const IcArrowLeftIcon = ({
  color,
  className,
}: TIconProps): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.31077 12.75L10.5304 17.9697L9.46978 19.0303L2.96978 12.5303L2.43945 12L2.96978 11.4697L9.46978 4.96967L10.5304 6.03033L5.31077 11.25H20.5001V12.75H5.31077Z"
      fill={color ? colors[color] : '#191919'}
    />
  </svg>
);
