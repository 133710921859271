export const QUERY_PARAM_SCREEN = 'auth';
export enum EAuthScreen {
  LOGIN = 'login',
  SUCCESS_LOGIN = 'success-login',
  SUCCESS_FORGOT_PASSWORD = 'success-forgot-password',
  FORGOT_PASSWORD = 'forgot-password',
  SUCCESS_SIGNUP = 'success-signup',
  SIGNUP = 'signup',
  RESTORE_PASSWORD = 'restore-password',
  SUCCESS_RESTORE_PASSWORD = 'success-restore-password',
  SHOULD_CHANGE_PASSWORD = 'should-change-password',
  LOOKS_LIKE_EMPLOYEE = 'looks-like-employee',
  LOOKS_LIKE_EMPLOYEE_SUCCESS = 'looks-like-employee-success',
}
