import { deserialize, JsonArray } from 'tserialize';
import { CartPositions } from './cart-position.model';

/** Модель ответа на добавление позиции в корзину */
export class AddCartPositionResponseModel {
  /** Список позиций */
  @JsonArray(CartPositions) positions: Array<CartPositions> = [];

  static fromServer(data: unknown): AddCartPositionResponseModel {
    return deserialize(data, this);
  }
}
